import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Stack, Grid, Chip } from '@mui/material';
import { useContext } from 'react';
import { applicationContext } from 'helpers/services';

interface IProps {
    children?: React.ReactNode
}

let NavBar = ({ children }: IProps) => {
    let appService = useContext(applicationContext);

    return (
        <AppBar color="inherit" elevation={0} position="sticky" style={{ borderBottom: '1px solid #eeeeee' }}>
            <Toolbar variant="dense" >
                <Grid container spacing={1} alignItems='center'>
                    <Grid item xs={3} md={2}>
                        {appService.isDesktop &&
                            <Stack direction="row" display='flex' textAlign='center'>
                                <Link to="/">
                                    <img height={22} src="/images/logo-white.png" />
                                    {process.env.REACT_APP_ENV !== "production" &&
                                        <>
                                            {process.env.REACT_APP_ENV === "development" &&
                                                <Chip size='small' label="dev" style={{ backgroundColor: '#ffffffaa', marginLeft: -4, marginTop: -24 }} />
                                            }
                                            {process.env.REACT_APP_ENV === "staging" &&
                                                <Chip size='small' label="staging" style={{ backgroundColor: '#ffffffaa', marginLeft: -4, marginTop: -24 }} />
                                            }
                                        </>
                                    }
                                </Link>
                            </Stack>
                        }
                        {!appService.isDesktop &&
                            <>
                                <Link to="/">
                                    <img height={22} src="/images/logo-white.png" />
                                    {process.env.REACT_APP_ENV !== "production" &&
                                        <>
                                            {process.env.REACT_APP_ENV === "development" &&
                                                <Chip size='small' label="dev" style={{ backgroundColor: '#ffffffaa', position: 'absolute', top: 8 }} />
                                            }
                                            {process.env.REACT_APP_ENV === "staging" &&
                                                <Chip size='small' label="staging" style={{ backgroundColor: '#ffffffaa', position: 'absolute', top: 8 }} />
                                            }
                                        </>
                                    }
                                </Link>
                            </>
                        }
                    </Grid>
                    <Grid item xs>
                        {children}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default NavBar;