import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

interface ICompanyContext {
    isLoading: boolean
    company: Company

    saveCompany: (company: Company) => void
}


export let companySchema = yup.object({
    companyId: yup.string().nullable().default(null),
    companyName: yup.string().required().default('').label('Company name'),
    vatNumber: yup.string().required().default('').label('VAT number'),
    street: yup.string().default('').label('Street'),
    postalCode: yup.string().default('').label('Postal Code'),
    city: yup.string().default('').label('City'),
    province: yup.string().default('').label('Province'),
    country: yup.string().default('').label('Country'),
    businessCardPreviewColor: yup.string().default('').label('color'),
    logoId: yup.string().nullable().default('').label('Logo'),
    sdiCode: yup.string().default('').label('sdiCode'),
});

type Company = yup.InferType<typeof companySchema>;

export let companyDetailsContext = React.createContext({} as ICompanyContext);

let { Provider } = companyDetailsContext;

let CompanyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { id } = useParams<{ id: string }>();
    let location = useLocation();
    let navigate = useNavigate();
    let { enqueueSnackbar } = useSnackbar();

    let isNew = location.pathname.includes("/addnew");

    let [isLoading, setLoading] = useState<boolean>(isNew === false);
    let [company, setCompany] = useState<Company>(companySchema.getDefault());


    useEffect(() => {
        if (isNew === false)
            getCompany();

    }, [id, isNew])

    let getCompany = async () => {
        try {
            let res = await axios.get('/api/Company/GetCompany', { params: { id } });

            let { item } = res.data;
            setCompany({
                ...item,
            });
            setLoading(false);

        } catch (err) { }
        finally {
        }
    }

    let saveCompany = async (value: Company) => {
        try {
            await axios.post('/api/Company/SaveCompany', value);
            enqueueSnackbar("Company saved", { variant: "success" });
            navigate(`/admin/companies`);

        } catch (err) {
        }
    }

    return (
        <Provider
            value={{
                isLoading,
                company,

                saveCompany
            }}
        >
            {children}
        </Provider>
    )
}

export default CompanyProvider;