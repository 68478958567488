import { useContext } from 'react';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableFooter, Typography } from '@mui/material';
import { addressesContext } from '../AddressesContext';
import { CircleLoader, DeleteIconButton, EditIconButton } from 'components';
import { applicationContext } from 'helpers/services';

let Results = () => {
    let service = useContext(addressesContext);
    let appService = useContext(applicationContext);

    return (
        <div>
            {service.isLoading && <CircleLoader />}
            {service.isLoading === false &&
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Street</TableCell>
                            <TableCell>Postal Code</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Province</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {service.addresses.length > 0 &&
                            service.addresses
                                .map((address, index) =>
                                    <TableRow hover key={index}>
                                        <TableCell>{address.street}</TableCell>
                                        <TableCell>{address.postalCode}</TableCell>
                                        <TableCell>{address.city}</TableCell>
                                        <TableCell>{address.province}</TableCell>
                                        <TableCell>
                                            {address.country && appService.referenceData.Country.getValueByKey(address.country, 'isoCode', 'countryName')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {appService.currentUser.canEditAddresses === true &&
                                                <>
                                                    <EditIconButton link={`/admin/address/edit/${address.addressId}`} />
                                                    <DeleteIconButton onClick={() => service.deleteAddress(address.addressId)} />
                                                </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )}
                    </TableBody>
                    {service.addresses.length === 0 &&
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Typography variant="subtitle2">No addresses found</Typography>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    }
                </Table>
            }
            {service.isLoading === false &&
                <TablePagination
                    component="div"
                    count={service.recordCount}
                    onPageChange={service.handleChangePage}
                    page={service.search.pageNr}
                    rowsPerPage={service.search.pageSize}
                    rowsPerPageOptions={[service.search.pageSize]}
                />
            }
        </div >
    );
}

export default Results;