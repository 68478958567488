export default {
    CONFIG: {
        Auth: {
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            // identityPoolId: 'eu-west-1:fa4bbf96-3f42-4e59-868a-96af1290e85f',

            // REQUIRED - Amazon Cognito Region
            region: 'eu-west-1',

            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: 'eu-west-1_RS33m6lBQ',

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: '5okjbvoob9gud54fobpksckagt',

            // OPTIONAL - customized storage object
            storage: localStorage,

            // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
            authenticationFlowType: 'USER_SRP_AUTH',
            // OPTIONAL - Hosted UI configuration
            oauth: {
                domain: 'passionebeauty.auth.eu-west-1.amazoncognito.com',
                responseType: 'code',
                redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
                redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT
                // scope: ['email', 'openid'],//['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                // responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
            }
        }
    }
}