import UserProvider from './UserDetailsContext';
import UserDetails from './UserDetails';
import { Page } from 'components';

let Index = () => {
    return (
        <UserProvider>
            <Page title='User details' maxWidth='md'>
                <UserDetails />
            </Page>
        </UserProvider>
    )
}

export default Index;