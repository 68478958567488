import { Grid, Typography } from '@mui/material';
import { GroupHeader } from 'components';
import { useContext } from 'react';
import { employeeContext } from '../EmployeeContext';
import { applicationContext } from 'helpers/services';


let Contacts = () => {
    let service = useContext(employeeContext);
    let { isDesktop } = useContext(applicationContext);

    return (
        <Grid container sx={{ marginTop: 1 }}>
            <Grid item xs={12}>
                <GroupHeader title='Contacts' />
            </Grid>
            {isDesktop &&
                <Grid item xs={12}>
                    <Grid container sx={{ paddingLeft: 4 }}>
                        <Grid item xs={4} md={2}>
                            <Typography>Telephone number</Typography>
                        </Grid>
                        <Grid item xs={8} md={10}>
                            <a
                                style={{ cursor: service.employee.telephoneNumber ? 'pointer' : 'inherit' }}
                                href={`tel:${service.employee.telephoneNumber}`}
                            >
                                {service.employee.telephoneNumber}
                            </a>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Typography>Mobile</Typography>
                        </Grid>
                        <Grid item xs={8} md={10}>
                            <a
                                style={{ cursor: service.employee.mobile ? 'pointer' : 'inherit' }}
                                href={`tel:${service.employee.mobile}`}
                            >
                                {service.employee.mobile}
                            </a>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Typography>Internal number</Typography>
                        </Grid>
                        <Grid item xs={8} md={10}>
                            <Typography>{service.employee.internalNumber}</Typography>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Typography>Email</Typography>
                        </Grid>
                        <Grid item xs={8} md={10}>
                            <a
                                style={{ cursor: service.employee.email ? 'pointer' : 'inherit' }}
                                href={`mailto:${service.employee.email}`}
                            >
                                {service.employee.email}
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {isDesktop == false &&
                <Grid item xs={12}>
                    <Grid container sx={{ paddingLeft: 4 }}>
                        <Grid item xs={12}>
                            <a
                                style={{ cursor: service.employee.telephoneNumber ? 'pointer' : 'inherit' }}
                                href={`tel:${service.employee.telephoneNumber}`}
                            >
                                {service.employee.telephoneNumber}
                            </a>
                            <Typography variant='body2'>Telephone number</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <a
                                style={{ cursor: service.employee.mobile ? 'pointer' : 'inherit' }}
                                href={`tel:${service.employee.mobile}`}
                            >
                                {service.employee.mobile}
                            </a>
                            <Typography variant='body2'>Mobile</Typography>
                        </Grid>
                        {service.employee.internalNumber &&
                            <Grid item xs={12}>
                                <Typography>{service.employee.internalNumber}</Typography>
                                <Typography variant='body2'>Internal number</Typography>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <a
                                style={{ cursor: service.employee.email ? 'pointer' : 'inherit' }}
                                href={`mailto:${service.employee.email}`}
                            >
                                {service.employee.email}
                            </a>
                            <Typography variant='body2'>Email</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}

export default Contacts;