import { Grid, Typography } from '@mui/material';
import { GroupHeader } from 'components';
import { useContext } from 'react';
import { employeeContext } from '../EmployeeContext';
import { applicationContext } from 'helpers/services';


let EmployeeInfo = () => {
    let service = useContext(employeeContext);
    let { isDesktop } = useContext(applicationContext);

    return (
        <Grid container>
            <Grid item xs={12}>
                <GroupHeader title='Overview' />
            </Grid>
            {isDesktop &&
                <Grid item xs={12}>
                    <Grid container sx={{ paddingLeft: 4 }}>
                        <Grid item xs={4} md={2}>
                            <Typography>Job title</Typography>
                        </Grid>
                        <Grid item xs={8} md={10}>
                            <Typography>{service.employee.jobTitle}</Typography>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Typography>Company</Typography>
                        </Grid>
                        <Grid item xs={8} md={10}>
                            <Typography>{service.employee.companyName}</Typography>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Typography>Department</Typography>
                        </Grid>
                        <Grid item xs={8} md={10}>
                            <Typography>{service.employee.department}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {isDesktop === false &&
                <Grid item xs={12}>
                    <Grid container sx={{ paddingLeft: 4 }}>
                        <Grid item xs={12}>
                            <Typography>{service.employee.jobTitle}</Typography>
                            <Typography variant='body2'>Job title</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{service.employee.companyName}</Typography>
                            <Typography variant='body2'>Company</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{service.employee.department}</Typography>
                            <Typography variant='body2'>Department</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}

export default EmployeeInfo;