import React, { useContext } from 'react';
import { Formik } from 'formik';
import { CircleLoader } from 'components';
import { referenceDataDetailContext, referenceDataSchema } from './ReferenceDataDetailContext';
import { ReferenceDataForm } from './components';


let ReferenceDataDetail = () => {
    let service = useContext(referenceDataDetailContext);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={service.referenceData}
            validationSchema={referenceDataSchema}
            onSubmit={async (values, action) => {
                await service.saveReferenceData(values);
                action.setSubmitting(false);
            }}
        >
            <>
                {service.isLoading && <CircleLoader />}
                {service.isLoading === false && <ReferenceDataForm isSorting={service.isSorting} sortBy={service.sortBy} />}
            </>
        </Formik>
    )
}

export default ReferenceDataDetail;