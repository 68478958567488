import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { authContext, ApplicationProvider } from 'helpers/services';
import { CircleLoader } from 'components';
import PrivateLayout from './PrivateLayout';


interface IProps {
    children: React.ReactNode
}

const PrivateRoute = ({ children }: IProps) => {
    let location = useLocation();
    let authService = useContext(authContext);

    const returnUrl = `/account/login?returnUrl=${location.pathname}`;

    return (
        <>
            {authService.isLoading === true && <CircleLoader />}
            {authService.isLoading === false &&
                <>
                    {authService.isAuthenticated === false && <Navigate to={returnUrl} />}
                    {authService.isAuthenticated === true &&
                        <>
                            <ApplicationProvider>
                                <PrivateLayout>
                                    {children}
                                </PrivateLayout>
                            </ApplicationProvider>
                        </>
                    }
                </>
            }
        </>
    );
}

export default PrivateRoute;