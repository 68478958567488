import { useContext, useState } from 'react';
import { Table, TableBody, TableCell, Tooltip, Zoom, TableHead, TablePagination, TableRow, IconButton, TableFooter, Typography } from '@mui/material';
import { SearchOutlined as DetailsIcon } from '@mui/icons-material';
import { IEmployee, employeesContext } from '../EmployeesContext';
import { CannotEditEmployee, CircleLoader, EditIconButton } from 'components';
import { Link, useNavigate } from 'react-router-dom';
import { applicationContext } from 'helpers/services';
import { ReferenceDataKey } from 'helpers/enums';

let ResultsDesktop = () => {
    let service = useContext(employeesContext);
    let { currentUser, referenceData } = useContext(applicationContext);
    let navigate = useNavigate();

    let [openEditAlert, setOpenEditAlert] = useState<moment.Moment | undefined>(undefined);


    let handleEditEmployee = (employee: IEmployee) => {
        // if (employee.canBeEdited === true)
        navigate(`/employee/edit/${employee.id}`);
        // else
        //     setOpenEditAlert(employee.canBeEditedFrom);
    }


    return (
        <div>
            {service.isLoading && <CircleLoader />}
            {service.isLoading === false &&
                <>
                    <TablePagination
                        component="div"
                        count={service.recordCount}
                        onPageChange={service.handleChangePage}
                        page={service.search.pageNr}
                        rowsPerPage={service.search.pageSize}
                        rowsPerPageOptions={[service.search.pageSize]}
                        labelDisplayedRows={(props) => {
                            let pageSize = props.to - props.from + 1
                            return (
                                <>
                                    Total employees:&nbsp;{service.recordCount} &nbsp;&middot;&nbsp;
                                    <span>Page {props.page + 1} of {Math.ceil(props.count / pageSize)}</span>
                                </>
                            )
                        }}
                    />

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Job title</TableCell>
                                <TableCell>Phone numbers</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Company</TableCell>
                                <TableCell>Department</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {service.employees.length > 0 &&
                                service.employees
                                    .map((employee, index) =>
                                        <TableRow hover key={index}>
                                            <TableCell>{employee.name}</TableCell>
                                            <TableCell>{employee.jobTitle}</TableCell>
                                            <TableCell>
                                                {employee.telephoneNumber && <>Telephone: {employee.telephoneNumber}<br /></>}
                                                {employee.mobile && <>Mobile: {employee.mobile}<br /></>}
                                                {employee.internalNumber && <>Internal: {employee.internalNumber}<br /></>}
                                            </TableCell>
                                            <TableCell>{employee.email}</TableCell>
                                            <TableCell>{employee.company}</TableCell>
                                            <TableCell>{employee.department}</TableCell>
                                            <TableCell align="right">
                                                {/* {((employee.company !== '' && currentUser.enabledCompanies.findIndex(x => x === employee.company) !== -1)
                                                    && currentUser.canEditUsers) &&
                                                    <EditIconButton onClick={() => handleEditEmployee(employee)} />
                                                } */}
                                                <Tooltip TransitionComponent={Zoom} title="Details">
                                                    <IconButton
                                                        color="secondary"
                                                        size="small"
                                                        component={Link}
                                                        to={`/employee/${employee.id}`}
                                                    >
                                                        <DetailsIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )}
                        </TableBody>
                        {service.employees.length === 0 &&
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <Typography variant="subtitle2">No employees found</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        }
                    </Table>
                </>
            }
            {service.isLoading === false &&
                <TablePagination
                    component="div"
                    count={service.recordCount}
                    onPageChange={service.handleChangePage}
                    page={service.search.pageNr}
                    rowsPerPage={service.search.pageSize}
                    rowsPerPageOptions={[service.search.pageSize]}
                    labelDisplayedRows={(props) => {
                        let pageSize = props.to - props.from + 1
                        return (
                            <>
                                Total employees:&nbsp;{service.recordCount} &nbsp;&middot;&nbsp;
                                <span>Page {props.page + 1} of {Math.ceil(props.count / pageSize)}</span>
                            </>
                        )
                    }}
                />
            }
            {openEditAlert !== undefined &&
                <CannotEditEmployee
                    open={openEditAlert !== undefined}
                    canEditFrom={openEditAlert}
                    onClose={() => setOpenEditAlert(undefined)}
                />
            }
        </div>
    );
}

export default ResultsDesktop;