import { useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, CardMedia, Grid, Typography, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { authContext } from 'helpers/services';
import { Page } from 'components';
import theme from 'assets';


let Login = () => {
    let location = useLocation();

    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    let authService = useContext(authContext);

    let [isLoading, setLoading] = useState(true);
    let [returnUrl, setReturnUrl] = useState<string | undefined>();
    let [errors, setErrors] = useState<string | undefined>();


    useEffect(() => {
        let prepareReturnUrl = () => {
            if (location.search) {
                let params = new URLSearchParams(location.search);

                let errors = params.get("error");
                if (errors)
                    setErrors(errors);

                let returnUrl = params.get("returnUrl");
                if (returnUrl)
                    setReturnUrl(returnUrl);
            }

            setLoading(false);
        }

        prepareReturnUrl();
    }, [])

    return (
        <>
            {isLoading === false &&
                <Page title='Login'>
                    <Card
                        style={{
                            width: isDesktop ? 600 : '100%',
                            height: 250,
                            margin: '32px auto',
                            backgroundImage: 'url(/images/homepage.jpg)',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >
                        <CardContent style={{ height: '100%', padding: 0, marginLeft: 8 }}>
                            <Grid container alignItems='center' textAlign='center' style={{ height: '100%' }} >
                                <Grid item xs={12} marginTop={4}>
                                    <img height={40} src="/images/logo-black.png" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4">PASSIONE BEAUTY PEOPLE</Typography>
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={async () => await authService.signInWithAd(returnUrl)}
                                    >
                                        Login for Passione Beauty Employees
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography align="justify" color="error">{errors}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Page >

            }
        </>
    )
}

export default Login;