import React from 'react';
import { Page } from 'components';
import { CompaniesProvider } from './CompaniesContext';
import Companies from './Companies';


let Index = () => {
    return (
        <CompaniesProvider>
            <Page title="Addresses">
                <Companies />
            </Page>
        </CompaniesProvider>
    );
}

export default Index;