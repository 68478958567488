import AddressProvider from './AddressDetailsContext';
import AddressDetails from './AddressDetails';
import { Page } from 'components';

let Index = () => {
    return (
        <AddressProvider>
            <Page title='Address details' maxWidth='md'>
                <AddressDetails />
            </Page>
        </AddressProvider>
    )
}

export default Index;