import { useContext } from "react";
import { companyDetailsContext, companySchema } from "./CompanyDetailsContext";
import { Formik } from "formik";
import { CircleLoader } from "components";
import CompanyForm from "./components";


let CompanyDetails = () => {

    let service = useContext(companyDetailsContext);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={service.company}
            validationSchema={companySchema}
            onSubmit={(values, action) => {
                service.saveCompany(values);
                action.setSubmitting(false);;
            }}
        >
            <>
                {service.isLoading && <CircleLoader />}
                {service.isLoading === false && <CompanyForm />}
            </>
        </Formik>
    );
}

export default CompanyDetails;