import React from 'react';
import { Page } from 'components';
import ReferenceDataDetail from './ReferenceDataDetail';
import ReferenceDataDetailProvider from './ReferenceDataDetailContext';

let Index = () => {
    return (
        <ReferenceDataDetailProvider>
            <Page title="Reference data details"maxWidth='md'>
                <ReferenceDataDetail />
            </Page>
        </ReferenceDataDetailProvider>
    );
}

export default Index;