import { colors } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import './scss/main.scss';
import palette from './palette';

const spacing = 8;
const radius = 0;
const appbarColor = '#000000';

const theme = createTheme({
    spacing: spacing,
    typography: {
        fontFamily: ['Muli'].join(','),
        h1: {
            color: palette.text.primary,
            fontWeight: 600,
            fontSize: '35px',
            letterSpacing: '-0.24px',
            lineHeight: '40px'
        },
        h2: {
            color: palette.text.primary,
            fontWeight: 600,
            fontSize: '29px',
            letterSpacing: '-0.24px',
            lineHeight: '32px'
        },
        h3: {
            color: palette.text.primary,
            fontWeight: 600,
            fontSize: '24px',
            letterSpacing: '-0.06px',
            lineHeight: '28px'
        },
        h4: {
            color: palette.text.primary,
            fontWeight: 600,
            fontSize: '20px',
            letterSpacing: '-0.06px',
            lineHeight: '24px'
        },
        h5: {
            color: palette.text.primary,
            fontWeight: 600,
            fontSize: '16px',
            letterSpacing: '-0.05px',
            lineHeight: '20px'
        },
        h6: {
            color: palette.text.primary,
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '-0.05px',
            lineHeight: '20px'
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    color: "white",
                    backgroundColor: appbarColor,
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    borderRadius: radius,
                    color: 'white',
                    backgroundColor: palette.primary.main,
                    '&:hover': {
                        backgroundColor: palette.primary.light
                    }
                },
                containedSecondary: {
                    borderRadius: radius,
                    color: 'white',
                    backgroundColor: palette.primary.main,
                    '&:hover': {
                        backgroundColor: palette.primary.main
                    }
                },
                outlined: {
                    color: palette.icon,
                    border: `2px solid ${palette.primary.main}`,
                    '&:hover': {
                        border: `2px solid ${palette.primary.main}`,
                    }
                },
                textPrimary: {
                    fontSize: 14,
                    color: palette.primary.main,
                    padding: 5,
                    backgroundColor: '#fafafa',
                    '&:hover': {
                        backgroundColor: '#ececec'
                    }
                },
                textSecondary: {
                    fontSize: 14,
                    color: 'black',
                    padding: 5,
                    '&:hover': {
                        backgroundColor: '#2632380a'
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                outlinedPrimary: {
                    color: palette.primary.main,
                    borderRadius: '4px',
                    border: `1px solid ${palette.secondary.main}`
                },
                outlinedSecondary: {
                    color: palette.primary.main,
                    borderRadius: '4px',
                    border: `1px solid ${palette.secondary.main}`
                },
            }
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: false
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    marginTop: spacing,
                    marginBottom: spacing,
                }
            }
        },
        MuiGrid: {
            defaultProps: {
                spacing: 2
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: colors.blueGrey[600],
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.03)'
                    }
                },
                colorPrimary: {
                    color: '#ffad33',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.03)'
                    }
                },
                colorSecondary: {
                    color: '#4c4c4c',
                    '&:hover': {
                        backgroundColor: '#4c4c4c42'
                    }
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    padding: spacing
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: `${spacing}px ${spacing * 2}px ${spacing}px ${spacing * 2}px`
                },
                head: {
                    fontWeight: 600
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.grey[50],
                    fontWeight: 600
                }
            }
        },
        MuiTablePagination: {
            defaultProps: {
                labelDisplayedRows(props) {
                    let pageSize = props.to - props.from + 1
                    return (<span>Page {props.page + 1} of {Math.ceil(props.count / pageSize)}</span>)
                }
            },
        },
        MuiTableRow: {
            styleOverrides: {
                "root": {
                    "&.MuiTableRow-selected": {
                        backgroundColor: "#de6c5973",
                        "&:hover": {
                            backgroundColor: "#de6c5973"
                        }
                    },
                    '&.MuiTableRow-hover': {
                        '&:hover': {
                            boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, .4)'
                        }
                    }
                },
            }
        }
    }
});


export default theme;