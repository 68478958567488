import React, { useContext } from 'react';
import { NavBar } from 'components';
import UserMenu from './UserMenu';
import AdminMenu from './AdminMenu';
import { Grid, List, ListItem, ListItemText, Stack } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { applicationContext } from 'helpers/services';
import palette from 'assets/palette';

interface IProps {
    children: React.ReactNode
}

const PrivateLayout = ({ children }: IProps) => {
    let location = useLocation();
    let appService = useContext(applicationContext);

    const pages = [
        { url: '/employees', labelId: 'Employees' },
    ]
    return (
        <>
            {appService.isLoading === false &&
                <>
                    <NavBar>
                        <Grid container alignItems='center'>
                            <Grid item xs style={{ marginLeft: appService.isDesktop === false ? 16 : 'auto' }}>
                                <List
                                    disablePadding
                                    component="nav"
                                    style={{
                                        listStyle: 'none',
                                        display: 'inline-flex',
                                    }}
                                >
                                    {pages.map(page =>
                                        <ListItem
                                            key={page.url}
                                            style={{ textTransform: 'uppercase', }}
                                            component={Link}
                                            to={page.url}
                                        >
                                            <ListItemText
                                                primary={
                                                    <strong style={{
                                                        fontSize: 13,
                                                        fontWeight: 800,
                                                        paddingBottom: '16px',
                                                        borderBottom: page.url === location.pathname ? `2px solid ${palette.primary.main}` : '0px',
                                                        color: page.url === location.pathname ? `${palette.primary.main}` : 'white',
                                                    }}>
                                                        {page.labelId}
                                                    </strong>
                                                }
                                            />
                                        </ListItem>
                                    )}
                                </List>
                            </Grid>
                            <Grid item>
                                <Stack direction="row" display='flex' textAlign='center'>
                                    {(appService.currentUser.canEditUsers === true || appService.currentUser.canEditAddresses === true) &&
                                        <AdminMenu />
                                    }
                                    <UserMenu />
                                </Stack>
                            </Grid>
                        </Grid>
                    </NavBar>
                    {children}
                </>
            }
        </>
    );
}

export default PrivateLayout;