import { useContext } from 'react';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableFooter, Typography } from '@mui/material';
import { companiesContext } from '../CompaniesContext';
import { CircleLoader, DeleteIconButton, EditIconButton } from 'components';
import { applicationContext } from 'helpers/services';

let Results = () => {
    let service = useContext(companiesContext);
    let appService = useContext(applicationContext);

    return (
        <div>
            {service.isLoading && <CircleLoader />}
            {service.isLoading === false &&
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Company name</TableCell>
                            <TableCell>VAT number</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {service.companies.length > 0 &&
                            service.companies
                                .map((address, index) =>
                                    <TableRow hover key={index}>
                                        <TableCell>{address.companyName}</TableCell>
                                        <TableCell>{address.vatNumber}</TableCell>
                                        <TableCell>{address.street} {address.postalCode}, {address.city} {address.province}</TableCell>
                                        <TableCell>
                                            {address.country && appService.referenceData.Country.getValueByKey(address.country, 'isoCode', 'countryName')}
                                        </TableCell>
                                        <TableCell align="right">
                                            <EditIconButton link={`/admin/company/edit/${address.companyId}`} />
                                            <DeleteIconButton onClick={() => service.deleteCompany(address.companyId)} />
                                        </TableCell>
                                    </TableRow>
                                )}
                    </TableBody>
                    {service.companies.length === 0 &&
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Typography variant="subtitle2">No companies found</Typography>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    }
                </Table>
            }
            {service.isLoading === false &&
                <TablePagination
                    component="div"
                    count={service.recordCount}
                    onPageChange={service.handleChangePage}
                    page={service.search.pageNr}
                    rowsPerPage={service.search.pageSize}
                    rowsPerPageOptions={[service.search.pageSize]}
                />
            }
        </div >
    );
}

export default Results;