import CompanyDetailsProvider from './CompanyDetailsContext';
import CompanyDetails from './CompanyDetails';
import { Page } from 'components';

let Index = () => {
    return (
        <CompanyDetailsProvider>
            <Page title='Company details' maxWidth='md'>
                <CompanyDetails />
            </Page>
        </CompanyDetailsProvider>
    )
}

export default Index;