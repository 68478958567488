import { useContext } from 'react';
import { Formik } from 'formik';
import { CircleLoader } from 'components';
import EmployeeDetailsForm from './components';
import { employeeSchema, employeeDetailsContext } from './EmployeeDetailsContext';

let EmployeeDetails = () => {

    let service = useContext(employeeDetailsContext);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={service.employee}
            validationSchema={employeeSchema}
            onSubmit={(values, action) => {
                service.saveEmployee(values);
                action.setSubmitting(false);;
            }}
        >
            <>
                {service.isLoading && <CircleLoader />}
                {service.isLoading === false && <EmployeeDetailsForm />}
            </>
        </Formik>
    )
}

export default EmployeeDetails;