import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { Form, Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { Link, useLocation } from 'react-router-dom';
import theme from 'assets';
import { useContext } from 'react';
import { applicationContext } from 'helpers/services';
import { ChromePicker, ColorResult } from 'react-color';
import { companySchema } from '../CompanyDetailsContext';
import * as yup from 'yup';
import { Dropzone } from 'components';
import axios from 'axios';
import { useSnackbar } from 'notistack';

let CompanyForm = () => {
    let router = useLocation();
    let { enqueueSnackbar } = useSnackbar();

    let isNew = router.pathname.includes('/addnew');
    let title = isNew ? 'New company' : `Edit company`;

    let { referenceData } = useContext(applicationContext);

    type Company = yup.InferType<typeof companySchema>;
    let form = useFormikContext<Company>();



    let handleChangeColor = (color: ColorResult, event: any) => {
        var newColor = form.values.businessCardPreviewColor;
        if (color && color.rgb.a)
            newColor = color.hex + Math.round(color.rgb.a * 255).toString(16);
        else if (color) {
            newColor = color.hex;
        }

        form.setFieldValue('businessCardPreviewColor', newColor);
    }


    let uploadMedia = async (acceptedFiles: any[]) => {
        if (acceptedFiles.length > 1)
            enqueueSnackbar("Select 1 file", { variant: 'error' });


        acceptedFiles.forEach(async (file: any) => {
            let formData = new FormData();
            formData.append("file", file);

            // Carico il file nel server
            let res = await axios.post('/api/Media/UploadMedia', file);
            let { data } = res;

            form.setFieldValue("logoId", data.id);
        });


    }

    return (
        <Form>
            <Grid container alignItems='center'>
                <Grid item xs>
                    <Typography gutterBottom variant="h3">{title}</Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        component={Link}
                        to={'/admin/companies'}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
            <Grid container alignItems='flex-start' style={{ marginTop: theme.spacing(1) }}>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="companyName"
                        label="Company name"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="vatNumber"
                        label="VAT number"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        component={TextField}
                        name="street"
                        label="Street"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="postalCode"
                        label="Postal Code"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="city"
                        label="City"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="country"
                        label="Country"
                        fullWidth
                        margin="dense"
                        size='small'
                        select
                    >
                        {referenceData.Country
                            .map((locale, index) =>
                                <MenuItem key={index} value={locale.isoCode}>
                                    {locale.countryName}
                                </MenuItem>
                            )}
                    </Field>
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="province"
                        label="Province"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='caption'>background preview color</Typography>
                    <ChromePicker
                        // disableAlpha
                        styles={{
                            default: {
                                picker: {
                                    marginTop: 16
                                }
                            }
                        }}
                        color={form.values.businessCardPreviewColor}
                        onChange={handleChangeColor}
                    />
                </Grid>
                {/* <Grid item xs={6}>
                    <Typography variant='caption'>Logo</Typography>
                    <Dropzone isMultiple={false} accept={{ key: [".png"] }} onDropCallback={uploadMedia} />
                </Grid> */}
            </Grid>
        </Form>
    );
}

export default CompanyForm;