import { Tooltip, Zoom, IconButton } from '@mui/material';
import { TouchApp as SelectIcon } from '@mui/icons-material';


interface IProps {
    size?: 'medium' | 'small'
    link?: string
    onClick?: () => void
}

let SelectIconButton = ({ size = 'small', onClick }: IProps) => {
    return (
        <Tooltip title="Select" TransitionComponent={Zoom}>
            <>
                <Tooltip TransitionComponent={Zoom} title="Select">
                    <IconButton
                        size={size}
                        color="primary"
                        onClick={ev => {
                            if (onClick)
                                onClick();
                        }}
                    >
                        <SelectIcon />
                    </IconButton>
                </Tooltip>
            </>
        </Tooltip>
    )
}

export default SelectIconButton;