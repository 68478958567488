import { Button, Checkbox, FormControlLabel, Grid, MenuItem, Typography } from '@mui/material';
import { Form, Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import * as yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import { employeeSchema } from '../EmployeeDetailsContext';
import theme from 'assets/theme';
import SelectAddress from './SelectAddress';
import { EditIconButton } from 'components';
import { useContext, useState } from 'react';
import { ReferenceDataKey } from 'helpers/enums';
import { applicationContext } from 'helpers/services';

let EmployeeDetailsForm = () => {
    let router = useLocation();
    let appService = useContext(applicationContext);

    let isNew = router.pathname.includes('/addnew');
    let title = isNew ? 'New employee' : 'Edit employee';

    type Employee = yup.InferType<typeof employeeSchema>;
    let form = useFormikContext<Employee>();

    let [showEditAddress, setShowEditAddress] = useState<boolean>(false);

    let openEditAddress = () => {
        setShowEditAddress(true);
    }

    let closeEditAddress = () => {
        setShowEditAddress(false);
    }


    return (
        <Form>
            <Grid container alignItems='center' spacing={2}>
                <Grid item xs>
                    <Typography gutterBottom variant="h3">{title}</Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        component={Link}
                        to={'/employees'}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
            <Grid container alignItems='center' spacing={1} sx={{ marginTop: theme.spacing(2) }}>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="firstName"
                        label="First name"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="lastName"
                        label="Last name"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="jobTitle"
                        label="Job title"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        select
                        name="company"
                        label="Company"
                        fullWidth
                        margin="dense"
                        size='small'
                    >
                        <MenuItem key={''} value={''}>Any</MenuItem>
                        {appService.referenceData.values[ReferenceDataKey.Company].map(company =>
                            <MenuItem key={company.key} value={company.key}>{company.value}</MenuItem>
                        )}
                    </Field>
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="department"
                        label="Department"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs>
                    <FormControlLabel
                        label="Enable QR code"
                        name="enableQRCode"
                        value={form.values.enableQRCode}
                        checked={form.values.enableQRCode}
                        onChange={(e: any) => form.setFieldValue('enableQRCode', e.target.checked)}
                        control={<Field type="checkbox" name="enableQRCode" component={Checkbox} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ marginTop: theme.spacing(1) }}>Contact</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="telephoneNumber"
                        label="Telephone number"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="mobile"
                        label="Mobile"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="internalNumber"
                        label="Internal number"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs>
                    <Field
                        component={TextField}
                        name="email"
                        label="Email"
                        disabled
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="h4" style={{ marginTop: theme.spacing(1) }}>Address</Typography>
                        </Grid>
                        <Grid item>
                            <EditIconButton onClick={() => openEditAddress()} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant='caption' color='textSecondary'>Street</Typography>
                    <Typography variant='body1' color='textPrimary'>{form.values.street}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' color='textSecondary'>Postal code</Typography>
                    <Typography variant='body1' color='textPrimary'>{form.values.postalCode}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' color='textSecondary'>City</Typography>
                    <Typography variant='body1' color='textPrimary'>{form.values.city}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' color='textSecondary'>Country</Typography>
                    <Typography variant='body1' color='textPrimary'>{form.values.country}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' color='textSecondary'>Province</Typography>
                    <Typography variant='body1' color='textPrimary'>{form.values.province}</Typography>
                </Grid>
            </Grid>

            {showEditAddress === true &&
                <SelectAddress formProps={form} onClose={() => closeEditAddress()} />
            }

        </Form>
    );
}

export default EmployeeDetailsForm;