import { useContext } from 'react';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableFooter, Typography } from '@mui/material';
import { usersContext } from '../UsersContext';
import { CircleLoader, DeleteIconButton, EditIconButton } from 'components';
import Moment from 'react-moment';
import { applicationContext } from 'helpers/services';

let Results = () => {
    let service = useContext(usersContext);
    let appService = useContext(applicationContext);

    return (
        <div>
            {service.isLoading && <CircleLoader />}
            {service.isLoading === false &&
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Info</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {service.users.length > 0 &&
                            service.users
                                .map((user, index) =>
                                    <TableRow hover key={index}>
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>
                                            Created on: <Moment date={user.createdOn} format='LL' />
                                        </TableCell>
                                        <TableCell align="right">
                                            {appService.currentUser.canEditUsers === true &&
                                                <>
                                                    <EditIconButton link={`/admin/user/edit/${user.id}`} />
                                                    <DeleteIconButton onClick={() => service.deleteUser(user.id)} />
                                                </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )}
                    </TableBody>
                    {service.users.length === 0 &&
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Typography variant="subtitle2">No users found</Typography>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    }
                </Table>
            }
            {service.isLoading === false &&
                <TablePagination
                    component="div"
                    count={service.recordCount}
                    onPageChange={service.handleChangePage}
                    page={service.search.pageNr}
                    rowsPerPage={service.search.pageSize}
                    rowsPerPageOptions={[service.search.pageSize]}
                />
            }
        </div >
    );
}

export default Results;