import { useContext, useState } from 'react';
import { Tooltip, Zoom, TablePagination, IconButton, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { SearchOutlined as DetailsIcon } from '@mui/icons-material';
import { IEmployee, employeesContext } from '../EmployeesContext';
import { CannotEditEmployee, CircleLoader, EditIconButton } from 'components';
import { Link, useNavigate } from 'react-router-dom';
import { applicationContext } from 'helpers/services';
import { ReferenceDataKey } from 'helpers/enums';
import React from 'react';

let ResultsMobile = () => {
    let service = useContext(employeesContext);
    let { currentUser, referenceData } = useContext(applicationContext);
    let navigate = useNavigate();

    let [openEditAlert, setOpenEditAlert] = useState<moment.Moment | undefined>(undefined);


    let handleEditEmployee = (employee: IEmployee) => {
        // if (employee.canBeEdited === true)
        navigate(`/employee/edit/${employee.id}`);
        // else
        //     setOpenEditAlert(employee.canBeEditedFrom);
    }


    return (
        <div>
            {service.isLoading && <CircleLoader />}
            {service.isLoading === false &&
                <>
                    <TablePagination
                        component="div"
                        count={service.recordCount}
                        onPageChange={service.handleChangePage}
                        page={service.search.pageNr}
                        rowsPerPage={service.search.pageSize}
                        rowsPerPageOptions={[service.search.pageSize]}
                        labelDisplayedRows={(props) => {
                            let pageSize = props.to - props.from + 1
                            return (
                                <>
                                    Total employees:&nbsp;{service.recordCount} &nbsp;&middot;&nbsp;
                                    <span>Page {props.page + 1} of {Math.ceil(props.count / pageSize)}</span>
                                </>
                            )
                        }}
                    />

                    <List>
                        {service.employees.length > 0 &&
                            service.employees.map((employee, index) =>
                                <React.Fragment key={index}>
                                    <ListItem alignItems="flex-start"
                                        secondaryAction={
                                            <>
                                                {/* {((employee.company !== '' && currentUser.enabledCompanies.findIndex(x => x === employee.company) !== -1)
                                                    && currentUser.canEditUsers) &&
                                                    <EditIconButton onClick={() => handleEditEmployee(employee)} />
                                                } */}
                                                <Tooltip TransitionComponent={Zoom} title="Details">
                                                    <IconButton
                                                        color="secondary"
                                                        size="small"
                                                        component={Link}
                                                        to={`/employee/${employee.id}`}
                                                    >

                                                        <DetailsIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        <ListItemText
                                            style={{ maxWidth: '80%' }}
                                            primary={<b>{employee.name}</b>}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography>{employee.company}</Typography>
                                                    <Typography>{employee.jobTitle}</Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component="li" />
                                </React.Fragment>
                            )}
                        {service.employees.length === 0 &&
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary="No employees found"
                                />
                            </ListItem>
                        }
                    </List>
                </>
            }
            {service.isLoading === false &&
                <TablePagination
                    component="div"
                    count={service.recordCount}
                    onPageChange={service.handleChangePage}
                    page={service.search.pageNr}
                    rowsPerPage={service.search.pageSize}
                    rowsPerPageOptions={[service.search.pageSize]}
                    labelDisplayedRows={(props) => {
                        let pageSize = props.to - props.from + 1
                        return (
                            <>
                                Total employees:&nbsp;{service.recordCount} &nbsp;&middot;&nbsp;
                                <span>Page {props.page + 1} of {Math.ceil(props.count / pageSize)}</span>
                            </>
                        )
                    }}
                />
            }
            {openEditAlert !== undefined &&
                <CannotEditEmployee
                    open={openEditAlert !== undefined}
                    canEditFrom={openEditAlert}
                    onClose={() => setOpenEditAlert(undefined)}
                />
            }
        </div>
    );
}

export default ResultsMobile;