/* eslint-disable no-extend-native */
declare global {
    interface Array<T> {
        getValueByKey(key: any, keyField?: string, valueField?: string): any;
        orderBy(fieldName?: string, sortDescending?: boolean): Array<T>;
    }
}

Array.prototype.getValueByKey = function (key: any, keyField: string = 'key', valueField: string = 'value') {
    if (!key)
        return undefined;

    let obj = this.find((x: any) => x[keyField] === key);

    if (!obj)
        return undefined;

    return obj[valueField];
};

Array.prototype.orderBy = function (fieldName: string = 'value', sortDescending: boolean = false) {
    let obj = this.sort((a: any, b: any) => {
        if (typeof a[fieldName] === "number") {
            return sortDescending ? b[fieldName] - a[fieldName] : a[fieldName] - b[fieldName];
        }

        if (typeof a[fieldName] === "string") {
            let first = a[fieldName].toUpperCase(); // ignora maiuscole e minuscole
            let second = b[fieldName].toUpperCase(); // ignora maiuscole e minuscole

            if (sortDescending) {
                if (first > second) return -1;
                if (first < second) return 1;
            }
            else {
                if (first < second) return -1;
                if (first > second) return 1;
            }

            return 0;
        }

        return 0;
    });

    return obj;
};

export { };