import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';


interface IReferenceDataContext {
    isLoading: boolean
    referenceData: IReferenceData[]

    deleteReferenceData: (id: string) => Promise<void>
}

interface IReferenceData {
    id: string
    key: string
    createdBy: string
    createdOn: moment.Moment
    lastUpdatedBy: string
    lastUpdatedOn: moment.Moment
}

export let referenceDataContext = React.createContext({} as IReferenceDataContext);

let { Provider } = referenceDataContext;

let ReferenceDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let [isLoading, setLoading] = useState<boolean>(true);
    let [referenceData, setReferenceData] = useState<IReferenceData[]>([]);


    useEffect(() => {
        getReferenceData();
    }, [])


    let getReferenceData = async () => {
        try {
            let res = await axios.get('/api/ReferenceData/GetReferenceDatas');
            setReferenceData(res.data.items);
        } catch (err: any) { }
        finally {
            setLoading(false);
        }
    }

    let deleteReferenceData = async (id: string) => {
        if (window.confirm("Are you sure that you want to delete this reference data?")) {
            try {
                await axios.delete(`/api/ReferenceData/DeleteReferenceData/${id}`);
                await getReferenceData();
            } catch (err: any) { }
        }
    }


    return (
        <Provider
            value={{
                isLoading,
                referenceData,

                deleteReferenceData
            }}
        >
            {children}
        </Provider>
    )
}

export default ReferenceDataProvider;