import React from 'react';
import { Page } from 'components';
import { EmployeesProvider } from './EmployeesContext';
import Employees from './Employees';


let Index = () => {
    return (
        <EmployeesProvider>
            <Page title="Employees">
                <Employees />
            </Page>
        </EmployeesProvider>
    );
}

export default Index;