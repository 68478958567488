import React, { useContext } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { SearchOutlined as SearchIcon, AddOutlined as AddIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { companiesContext } from '../CompaniesContext';
import { applicationContext } from 'helpers/services';


let Filters = () => {
    let service = useContext(companiesContext);
    let { currentUser } = useContext(applicationContext);


    return (
        <>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    size="small"
                    placeholder="Search"
                    value={service.search.fullText}
                    onChange={(e: any) => service.updateSearch({
                        ...service.search,
                        fullText: e.target.value
                    })}
                />
            </Grid>
            <Grid item xs>
                <Button
                    type="submit"
                    variant="outlined"
                    aria-label="search"
                    onClick={() => service.doSearch(service.search)}
                >
                    <SearchIcon />
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="inherit"
                    component={Link}
                    to={'/admin/company/addnew'}
                >
                    <AddIcon />&nbsp;Add New
                </Button>
            </Grid>
        </>
    );
}

export default Filters;