import { useContext } from 'react';
import { Formik } from 'formik';
import { CircleLoader } from 'components';
import UserForm from './components';
import { userContext, userSchema } from './UserDetailsContext';

let UserDetails = () => {

    let service = useContext(userContext);

    return (
            <Formik
                enableReinitialize={true}
                initialValues={service.user}
                validationSchema={userSchema}
                onSubmit={(values, action) => {
                    service.saveUser(values);
                    action.setSubmitting(false);;
                }}
            >
                <>
                    {service.isLoading && <CircleLoader />}
                    {service.isLoading === false && <UserForm />}
                </>
            </Formik>
    )
}

export default UserDetails;