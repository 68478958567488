import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';


interface IAddressesContext {
    recordCount: number
    search: ISearch
    addresses: IAddress[]
    isLoading: boolean

    doSearch: (search: ISearch) => void
    handleChangePage: (event: any, page: number) => void
    updateSearch: (newSearch: ISearch) => void
    searchAddresses: (searchRequest: ISearch) => Promise<void>
    deleteAddress: (id: string) => Promise<void>
}

export let addressesContext = React.createContext<IAddressesContext>({} as IAddressesContext);

let { Provider } = addressesContext;

interface IAddress {
    addressId: string
    street: string
    postalCode: string
    city: string
    province: string
    country: string
}

export interface ISearch {
    fullText: string
    pageNr: number
    pageSize: number
}

export let AddressesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { enqueueSnackbar } = useSnackbar();
    let location = useLocation();
    let navigate = useNavigate();

    let [isLoading, setLoading] = useState(true);


    let [addresses, setAddresses] = useState<IAddress[]>([]);
    let [recordCount, setTotalRecords] = useState(0);

    let [search, setSearch] = useState<ISearch>({
        fullText: '',
        pageNr: 0,
        pageSize: 20
    });

    useEffect(() => {
        if (location.state)
            processState(location.state);

    }, [location.state])


    if (!location.state)
        return <Navigate to={location.pathname} state={{ search: search }} replace={true} />


    let processState = (state: any) => {
        if (state) {

            setSearch({
                ...search,
                ...state
            });

            searchAddresses({
                ...search,
                ...state
            });
        }
    }

    let doSearch = (values: ISearch) => {
        let request = {
            ...values,
            pageNr: values.pageNr > 0 ? 0 : values.pageNr
        }

        navigate(location.pathname, { state: request });
    }

    let updateSearch = (newSearch: ISearch) => {
        setSearch(newSearch);
    }

    let searchAddresses = async (searchRequest: ISearch) => {
        try {
            setLoading(true);
            let response = await axios.post('/api/Address/SearchAddresses', searchRequest);

            setAddresses(response.data.items);
            setTotalRecords(response.data.recordCount);
            setSearch({ ...searchRequest });

        } catch (error) { }
        finally {
            setLoading(false);
        }
    }

    let handleChangePage = (event: any, page: number) => {
        let request = {
            ...search,
            pageNr: page
        }

        window.scrollTo(0, 0);
        navigate(location.pathname, { state: request });
    }

    let deleteAddress = async (id: string) => {
        if (window.confirm("Are you sure you want to delete the address?")) {
            try {
                await axios.delete(`/api/Address/DeleteAddress/${id}`)
                setAddresses(addresses.filter(address => address.addressId !== id));
                setTotalRecords(recordCount - 1);
                enqueueSnackbar("Address deleted", { variant: 'success' });
            } catch (error) { }
        }
    }


    return (
        <Provider value={{
            recordCount,
            search,
            addresses,
            isLoading,

            doSearch,
            handleChangePage,
            updateSearch,
            searchAddresses,
            deleteAddress
        }}>
            {children}
        </Provider>
    );
};