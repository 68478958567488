import React, { useContext } from 'react';
import { Table, TableRow, TableHead, TableBody, TableCell, TableFooter, Tooltip, Zoom, IconButton, Typography } from '@mui/material';
import { referenceDataContext } from '../ReferenceDataContext';
import { Link as RouterLink } from 'react-router-dom';
import { CircleLoader } from 'components';
import Moment from 'react-moment';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';


let Results = () => {

    let service = useContext(referenceDataContext);


    return (
        <div >
            {service.isLoading && <CircleLoader />}
            {service.isLoading === false &&
                <div >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Key</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Updated</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {service.referenceData
                                .map((refData, index) =>
                                    <TableRow hover key={refData.id}>
                                        <TableCell>{refData.key}</TableCell>
                                        <TableCell>
                                            {refData.createdBy}&nbsp;&middot;&nbsp;<Moment date={refData.createdOn} format="LL" />
                                        </TableCell>
                                        <TableCell>
                                            {refData.lastUpdatedBy}&nbsp;&middot;&nbsp;<Moment date={refData.lastUpdatedOn} format="LL" />
                                        </TableCell>
                                        <TableCell align="right">
                                            <Tooltip TransitionComponent={Zoom} title="Edit">
                                                <IconButton
                                                    color="primary"
                                                    size="small"
                                                    component={RouterLink}
                                                    to={`/admin/referencedata/edit/${refData.id}`}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip TransitionComponent={Zoom} title="Delete">
                                                <IconButton
                                                    color="secondary"
                                                    size="small"
                                                    onClick={() => service.deleteReferenceData(refData.id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )}
                        </TableBody>
                        {service.isLoading === false && service.referenceData.length === 0 &&
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Typography variant="subtitle2">No reference data found</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        }
                    </Table>
                </div>
            }
        </div>
    )
}

export default Results;