import { useContext } from 'react';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { employeesContext } from '../EmployeesContext';
import { FilterContainer } from 'components';
import { applicationContext } from 'helpers/services';
import { ReferenceDataKey } from 'helpers/enums';

let Filters = () => {

    let service = useContext(employeesContext);
    let appService = useContext(applicationContext);

    return (
        <FilterContainer>
            <Grid item xs={12} md={2}>
                <TextField
                    name="fullText"
                    fullWidth
                    margin={appService.isDesktop ? "dense" : "none"}
                    size='small'
                    label="Search"
                    value={service.search.fullText}
                    onChange={(e: any) => service.updateSearch({
                        ...service.search,
                        fullText: e.target.value
                    })}
                />
            </Grid>
            <Grid item xs={12} md={2}>
                <TextField
                    label="Job title"
                    name="jobTitle"
                    fullWidth
                    margin={appService.isDesktop ? "dense" : "none"}
                    size='small'
                    value={service.search.jobTitle}
                    onChange={(e: any) => service.updateSearch({
                        ...service.search,
                        jobTitle: e.target.value
                    })}
                />
            </Grid>
            <Grid item xs={true} md={2}>
                <TextField
                    label="Companies"
                    name="companies"
                    fullWidth
                    margin={appService.isDesktop ? "dense" : "none"}
                    size='small'
                    select
                    SelectProps={{ multiple: true }}
                    value={service.search.companies}
                    onChange={(e: any) => service.updateSearch({
                        ...service.search,
                        companies: e.target.value
                    })}
                >
                    {appService.referenceData.values[ReferenceDataKey.Company].map(company =>
                        <MenuItem key={company.key} value={company.key}>{company.value}</MenuItem>
                    )}
                </TextField>
            </Grid>
            <Grid item>
                <Button
                    type="submit"
                    variant="outlined"
                    onClick={() => service.doSearch(service.search)}
                    size="medium"
                    color="primary"
                >
                    <SearchIcon />
                </Button>
            </Grid>
        </FilterContainer>
    )
}

export default Filters;