import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';

import 'helpers/Extensions';

import theme from 'assets';

import { Routes, AxiosInterceptors } from 'components';
import { AuthProvider } from 'helpers/services';
import { CssBaseline, ThemeProvider } from '@mui/material';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider preventDuplicate autoHideDuration={4000}>
                <BrowserRouter>
                    <AuthProvider>
                        <AxiosInterceptors />
                        <Routes />
                    </AuthProvider>
                </BrowserRouter>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
