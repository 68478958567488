import { Container } from '@mui/material';
import theme from 'assets';
import React from 'react';
import { Helmet } from 'react-helmet';


interface IPage {
  title: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  noPaddingTop?: boolean
  children?: React.ReactNode
  className?: string
}

const Page = ({ title, children, className, maxWidth, noPaddingTop, ...rest }: IPage) => {
  return (
    <div {...rest} className={className}>
      <Helmet>
        {process.env.NODE_ENV === "production" &&
          <title>Passione Beauty People | {title}</title>
        }
        {process.env.NODE_ENV !== 'production' &&
                  <title> Passione Beauty People {process.env.NODE_ENV} | {title}</title>
        }
      </Helmet>
      <Container maxWidth={maxWidth} sx={{ paddingTop: noPaddingTop ? 0 : theme.spacing(1) }}>
        {children}
      </Container>
    </div>
  );
};

export default Page;
