import React from 'react';
import { Page } from 'components';
import ReferenceData from './ReferenceData';
import ReferenceDataProvider from './ReferenceDataContext';


let Index = () => {
    return (
        <ReferenceDataProvider>
            <Page title="Reference data">
                <ReferenceData />
            </Page>
        </ReferenceDataProvider>
    );
}

export default Index;