import { useContext, useState } from 'react';
import { Menu, MenuItem, ListItem, ListItemText, Divider } from '@mui/material';
import { ExitToAppOutlined as SignOutIcon, AccountCircleOutlined as UserMenuIcon } from '@mui/icons-material';
import { applicationContext, authContext } from 'helpers/services';


let UserMenu = () => {
    let authService = useContext(authContext);
    let { currentUser } = useContext(applicationContext);

    let [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


    return (
        <>
            <ListItem id="login-menu" onClick={(e: any) => setAnchorEl(e.currentTarget)}>
                <ListItemText primary={<UserMenuIcon />} />
            </ListItem>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={anchorEl !== null}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem disabled>
                    {currentUser.firstName}&nbsp;{currentUser.lastName}<br />{currentUser?.email}
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={async () => {
                        setAnchorEl(null);
                        await authService.signOut();
                    }}
                >
                    <SignOutIcon />&nbsp;&nbsp;Logout
                </MenuItem>
            </Menu>
        </>
    )
}

export default UserMenu;