import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Results } from './components';
import { Link } from 'react-router-dom';
import { AddOutlined as AddIcon } from '@mui/icons-material';


let ReferenceData = () => {

    return (
        <Grid container>
            <Grid item xs>
                <Typography variant='h3'>Reference data</Typography>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="inherit"
                    component={Link}
                    to={'/admin/referencedata/addnew'}
                >
                    <AddIcon />&nbsp;Add New
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Results />
            </Grid>
        </Grid>
    )
}

export default ReferenceData;