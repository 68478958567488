import { Grid } from "@mui/material";
import { Filters, ResultsDesktop, ResultsMobile } from "./components";
import { applicationContext } from "helpers/services";
import { useContext } from "react";

let Employees = () => {
    let appService = useContext(applicationContext);

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Filters />
            </Grid>
            <Grid item xs={12}>
                {appService.isDesktop &&
                    <ResultsDesktop />
                }
                {appService.isDesktop === false &&
                    <ResultsMobile />
                }
            </Grid>
        </Grid>
    );
}

export default Employees;