import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { Link, useLocation } from 'react-router-dom';
import theme from 'assets';
import { useContext } from 'react';
import { applicationContext } from 'helpers/services';

let AddressForm = () => {
    let router = useLocation();

    let isNew = router.pathname.includes('/addnew');
    let title = isNew ? 'New address' : `Edit address`;

    let { referenceData } = useContext(applicationContext);

    return (
        <Form>
            <Grid container alignItems='center'>
                <Grid item xs>
                    <Typography gutterBottom variant="h3">{title}</Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        component={Link}
                        to={'/admin/addresses'}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
            <Grid container alignItems='center' style={{ marginTop: theme.spacing(1) }}>
                <Grid item xs={12}>
                    <Field
                        component={TextField}
                        name="street"
                        label="Street"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="postalCode"
                        label="Postal Code"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="city"
                        label="City"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="country"
                        label="Country"
                        fullWidth
                        margin="dense"
                        size='small'
                        select
                    >
                        {referenceData.Country
                            .map((locale, index) =>
                                <MenuItem key={index} value={locale.isoCode}>
                                    {locale.countryName}
                                </MenuItem>
                            )}
                    </Field>
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="province"
                        label="Province"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
            </Grid>
        </Form>
    );
}

export default AddressForm;