import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from "moment";

interface IEmployeesContext {
    recordCount: number
    search: ISearch
    employees: IEmployee[]
    isLoading: boolean
    anchorEl: Element | undefined
    selectedFilter: string

    updateSearch: (newSearch: ISearch) => void
    doSearch: (values: ISearch) => void
    searchEmployees: (searchRequest: ISearch) => Promise<void>
    handleChangePage: (event: any, page: number) => void
    openEdit: (filterName: string, event: any) => void
    closeEdit: () => void
}

export let employeesContext = React.createContext<IEmployeesContext>({} as IEmployeesContext);

let { Provider } = employeesContext;

export interface IEmployee {
    id: string
    name: string
    jobTitle: string
    department: string
    company: string
    telephoneNumber: string
    mobile: string
    internalNumber: string
    email: string
    canBeEdited: boolean
    canBeEditedFrom: moment.Moment | undefined

    createdOn: moment.Moment
    orderDate: moment.Moment
}

interface ISearch {
    fullText: string
    pageNr: number
    pageSize: number
    jobTitle: string
    companies: string[]
};

export let EmployeesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { enqueueSnackbar } = useSnackbar();
    let location = useLocation();
    let navigate = useNavigate();

    let [isLoading, setLoading] = useState(true);

    let [employees, setEmployees] = useState<IEmployee[]>([]);
    let [recordCount, setTotalRecords] = useState(0);
    let [selectedFilter, setSelectedFilter] = useState<string>('');
    let [anchorEl, setAnchorEl] = useState<Element>();

    let [search, setSearch] = useState<ISearch>({
        fullText: '',
        pageNr: 0,
        pageSize: 20,
        jobTitle: '',
        companies: [],
    });

    useEffect(() => {
        if (location.state)
            processState(location.state);

    }, [location.state])


    if (!location.state)
        return <Navigate to={location.pathname} state={{ search: search }} replace={true} />


    let processState = (state: any) => {
        if (state.search) {
            setSearch({
                ...search,
                ...state.search
            });

            searchEmployees({
                ...search,
                ...state.search
            });
        }
    }


    let doSearch = (values: ISearch) => {
        let request = {
            ...values,
            pageNr: values.pageNr > 0 ? 0 : values.pageNr
        }

        navigate(location.pathname, { state: { search: request } });

    }

    let updateSearch = (newSearch: ISearch) => {
        setSearch(newSearch);
    }

    let searchEmployees = async (searchRequest: ISearch) => {
        try {
            setLoading(true);
            let response = await axios.post('/api/Employee/SearchEmployees', searchRequest);

            setEmployees(response.data.items);
            setTotalRecords(response.data.recordCount);

        } catch (error) { }
        finally {
            setLoading(false);
        }
    }

    let handleChangePage = (event: any, page: number) => {
        let request = {
            ...search,
            pageNr: page
        }

        window.scrollTo(0, 0);
        navigate(location.pathname, { state: { search: request } });
    }


    let openEdit = (filterName: string, event: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedFilter(filterName);
    }

    let closeEdit = () => {
        setAnchorEl(undefined);
        setSelectedFilter('');
    }

    return (
        <Provider value={{
            recordCount,
            search,
            employees,
            isLoading,
            anchorEl,
            selectedFilter,

            updateSearch,
            doSearch,
            searchEmployees,
            handleChangePage,
            openEdit,
            closeEdit
        }}>
            {children}
        </Provider>
    );
};