import React, { useEffect, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import amplifyConf from './amplifyConf';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';

Amplify.configure(amplifyConf.CONFIG);

interface IAuthContext {
    isLoading: boolean
    isAuthenticated: boolean

    signInWithAd: (returnUrl?: string) => Promise<void>
    signOut: () => Promise<void>
    getToken: () => Promise<string | null>
    getAuthenticatedUser: () => Promise<any | undefined>
}

export let authContext = React.createContext({} as IAuthContext);

let { Provider } = authContext;

let AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { enqueueSnackbar } = useSnackbar();
    let navigate = useNavigate();
    let location = useLocation();

    let [isLoading, setLoading] = useState(true);
    let [isAuthenticated, setAuthenticated] = useState(false);


    useEffect(() => {
        getAuthenticatedUser().then(() => setLoading(false));
    }, [])


    Hub.listen("auth", async ({ payload: { event, data } }) => {
        switch (event) {
           
            case "customOAuthState":
                await getAuthenticatedUser();

                let decodedUrl = decodeURIComponent(data);
                if (decodedUrl) {
                    navigate(decodedUrl);
                } else
                    navigate('/');

                setLoading(false);
                break;

            // External user access
            // case "signIn":
            //     await getAuthenticatedUser();

            //     let returnUrl = '/';

            //     if (location.search) {
            //         let url = new URLSearchParams(location.search).get("returnUrl");
            //         if (url)
            //             returnUrl = url;
            //     }

            //     navigate(returnUrl);
            //     setLoading(false);
            //     break;

            case "tokenRefresh":
                await getAuthenticatedUser();
                setLoading(false);
                break;

            case 'signIn_failure':
                enqueueSnackbar(`Error during signin`, { variant: 'error' });
                navigate(`/account/login?error=${data}`, { replace: true });
                break;

            case 'oAuthSignOut':
            case "signOut":
                navigate(`/account/login`);
                break;

            default:
                setAuthenticated(false);
                setLoading(false);
                console.info(`[Passione Beauty People Directory] Not managed event: ${event}`);
                break;
        }
    });


    let signInWithAd = async (returnUrl?: string) => {
        try {
            await Auth.federatedSignIn({
                customProvider: "ActiveDirectory",
                customState: returnUrl
            });
        } catch (err) { }
    }

    let signOut = async () => {
        try {
            await Auth.signOut();
        } catch (err) {
            console.error(err);
        }
    }

    let getToken = async () => {
        try {
            let session = await Auth.currentSession();

            if (session.isValid())
                return session.getIdToken().getJwtToken();
            else
                return null;

        } catch (err) {
            console.error(err);
            return null;
        }
    }

    let getAuthenticatedUser = async () => {
        try {
            let cognitoUser = await Auth.currentAuthenticatedUser();
            setAuthenticated(true);
            return cognitoUser;

        } catch (err) {
            console.error(err);
            return undefined;
        }
    }


    return (
        <Provider
            value={{
                isLoading,
                isAuthenticated,

                signInWithAd,
                signOut,
                getToken,
                getAuthenticatedUser
            }}
        >
            {children}
        </Provider>
    )
}

export default AuthProvider;