import React from 'react';
import { Page } from 'components';
import { UsersProvider } from './UsersContext';
import Users from './Users';


let Index = () => {
    return (
        <UsersProvider>
            <Page title="Users">
                <Users />
            </Page>
        </UsersProvider>
    );
}

export default Index;