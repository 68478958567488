import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

interface IAddressContext {
    isLoading: boolean
    address: Address

    saveAddress: (address: Address) => void
}

export let addressSchema = yup.object({
    addressId: yup.string().nullable().default(null),
    street: yup.string().required().default('').label('Street'),
    postalCode: yup.string().required().default('').label('Postal Code'),
    city: yup.string().required().default('').label('City'),
    province: yup.string().required().default('').label('Province'),
    country: yup.string().required().default('').label('Country')
});

type Address = yup.InferType<typeof addressSchema>;

export let addressContext = React.createContext({} as IAddressContext);

let { Provider } = addressContext;

let AddressProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { id } = useParams<{ id: string }>();
    let location = useLocation();
    let navigate = useNavigate();
    let { enqueueSnackbar } = useSnackbar();

    let isNew = location.pathname.includes("/addnew");

    let [isLoading, setLoading] = useState<boolean>(isNew === false);
    let [address, setAddress] = useState<Address>(addressSchema.getDefault());


    useEffect(() => {
        if (isNew === false)
            getAddress();

    }, [id, isNew])

    let getAddress = async () => {
        try {
            let res = await axios.get('/api/Address/GetAddress', { params: { id } });

            let { item } = res.data;
            setAddress({
                ...item,
            });
            setLoading(false);

        } catch (err) { }
        finally {
        }
    }

    let saveAddress = async (value: Address) => {
        try {
            await axios.post('/api/Address/SaveAddress', value);
            enqueueSnackbar("Address saved", { variant: "success" });
            navigate(`/admin/addresses`);

        } catch (err) {
        }
    }

    return (
        <Provider
            value={{
                isLoading,
                address,

                saveAddress
            }}
        >
            {children}
        </Provider>
    )
}

export default AddressProvider;