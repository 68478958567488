import { Typography, Container, useMediaQuery } from '@mui/material';
import theme from 'assets/theme';


let Homepage = () => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <div style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            left: 0,
            right: 0,
            backgroundColor: '#FFF',
            backgroundPosition: 'right',
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'url(/images/homepage.jpg)',
            backgroundSize: 'cover'
        }}>
            <Container maxWidth="sm" style={{ marginTop: theme.spacing(8) }}>
                <Typography align="center" variant="h2">
                    Welcome to Passione Beauty
                </Typography>
                <Typography align="center" variant="subtitle2">
                    Choose a menu action to start!
                </Typography>
            </Container>
        </div>
    );
}

export default Homepage;