import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';

interface IEmployeeDetailsContext {
    isLoading: boolean
    employee: Employee
    lastEditedOn: moment.Moment | undefined
    canBeEditedFrom: moment.Moment | undefined

    saveEmployee: (employee: Employee) => void
}

export let employeeSchema = yup.object({
    id: yup.string().nullable().default(null),
    firstName: yup.string().required().default('').label('First name').typeError('First name is required'),
    lastName: yup.string().required().default('').label('Last name').typeError('Last name is required'),
    jobTitle: yup.string().default('').nullable().label('Job title'),
    company: yup.string().default('').nullable().label('Company'),
    department: yup.string().default('').nullable().label('Department'),
    street: yup.string().default('').nullable().label('Street'),
    postalCode: yup.string().default('').nullable().label('Postal code'),
    city: yup.string().default('').nullable().label('City'),
    province: yup.string().default('').nullable().label('Province'),
    country: yup.string().default('').nullable().label('Country'),
    telephoneNumber: yup.string().default('').nullable().label('Telephone number'),
    mobile: yup.string().default('').nullable().label('Mobile phone'),
    internalNumber: yup.string().default('').nullable().label('Internal number'),
    email: yup.string().nullable().default('').label("Email").typeError('Please insert a valid Email address'),
    enableQRCode: yup.boolean().default(false).label('Enable QR code'),
});

type Employee = yup.InferType<typeof employeeSchema>;

export let employeeDetailsContext = React.createContext({} as IEmployeeDetailsContext);

let { Provider } = employeeDetailsContext;

let EmployeeDetailsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { id } = useParams<{ id: string }>();
    let location = useLocation();
    let navigate = useNavigate();
    let { enqueueSnackbar } = useSnackbar();

    let isNew = location.pathname.includes("/addnew");

    let [isLoading, setLoading] = useState<boolean>(isNew === false);
    let [employee, setEmployee] = useState<Employee>(employeeSchema.getDefault());
    let [lastEditedOn, setLastEditedOn] = useState<moment.Moment | undefined>(undefined);
    let [canBeEditedFrom, setCanBeEditedFrom] = useState<moment.Moment | undefined>(undefined);


    useEffect(() => {
        if (isNew === false)
            getEmployee();

    }, [id, isNew])

    let getEmployee = async () => {
        try {
            let res = await axios.get('/api/Employee/GetEmployee', { params: { id } });

            let { item } = res.data;

            setEmployee({ ...item });
            setLastEditedOn(item.lastEditedOn);
            setCanBeEditedFrom(item.lastEditedOn);

            setLoading(false);

        } catch (err) { }
        finally {
        }
    }

    let saveEmployee = async (value: Employee) => {
        try {
            await axios.post('/api/Employee/SaveEmployee', value);
            enqueueSnackbar("Employee saved", { variant: "success" });
            navigate(`/employees`);

        } catch (err) { }
    }

    return (
        <Provider
            value={{
                isLoading,
                employee,
                lastEditedOn,
                canBeEditedFrom,

                saveEmployee
            }}
        >
            {children}
        </Provider>
    )
}

export default EmployeeDetailsProvider;