import { Button, CardMedia, Grid, Link } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmployeeInfo, Contacts, Header, Address } from './components';
import { employeeContext } from './EmployeeContext';
import { applicationContext } from 'helpers/services';
import axios from 'axios';
import { CannotEditEmployee } from 'components';


let Employee = () => {
    let { currentUser } = useContext(applicationContext);
    let service = useContext(employeeContext);
    let navigate = useNavigate();

    let [qrCodeUrl, setQrCodeUrl] = useState('');
    let [openEditAlert, setOpenEditAlert] = useState(false);

    useEffect(() => {
        if (service.isLoading === false)
            getEmployeeQrCode(false);

    }, [service.isLoading]);

    let getEmployeeQrCode = async (download: boolean) => {
        // if (service.employee.enableQRCode === false)
        //     return;

        try {

            let request = {
                employeeId: service.employee.id,
                download: download,
                backgroundTransparent: false
            }

            let res = await axios.get(`/api/QRCode/GetQrCode`, { params: request, responseType: 'blob' });

            let filename = "file.png";
            let disposition = res.headers['content-disposition'];

            if (disposition && disposition.indexOf('attachment') !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1])
                    filename = matches[1].replace(/['"]/g, '');
            }

            if (download === false) {
                let url = window.URL.createObjectURL(new Blob([res.data]));
                setQrCodeUrl(url);
            } else {
                let file = new Blob([res.data], { type: res.data.type });
                let url = window.URL.createObjectURL(file);
                let link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }

        } catch (err) { }
    }

    let handleEditEmployee = () => {
        // if (service.employee.canBeEdited === true)
        navigate(`/employee/edit/${service.employee.id}`);
        // else
        //     setOpenEditAlert(true);
    }

    return (
        <>
            {service.isLoading === false &&
                <>
                    <Header title={`${service.employee.firstName} ${service.employee.lastName}`}>
                        <></>
                    </Header>
                    {/* {((service.employee.company !== '' && currentUser.enabledCompanies.findIndex(x => x === service.employee.company) !== -1)
                            && currentUser.canEditUsers) &&
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={handleEditEmployee}
                                >
                                    Edit employee
                                </Button>
                            </Grid>
                        } */}
                    <Grid container>
                        <Grid item>
                            <CardMedia
                                component="img"
                                width={250}
                                sx={{ width: 250, border: '1px solid #fafafa' }}
                                src={qrCodeUrl !== '' ? qrCodeUrl : '/images/placeholder.png'}
                            />
                            <div style={{ textAlign: 'center', display: 'grid' }}>
                                <Button
                                    variant='text'
                                    onClick={() => getEmployeeQrCode(true)}
                                >
                                    Download QR code
                                </Button>
                                <Link
                                    sx={{ marginTop: 2 }}
                                    href={service.employee.previewPage}
                                >
                                    Open preview page
                                </Link>
                            </div>
                        </Grid>
                        <Grid item xs sx={{ marginBottom: 4 }}>
                            <EmployeeInfo />

                            <Contacts />

                            <Address />
                        </Grid>
                    </Grid>
                </>
            }
            {openEditAlert === true &&
                <CannotEditEmployee
                    open={openEditAlert}
                    canEditFrom={service.employee.canBeEditedFrom}
                    onClose={() => setOpenEditAlert(false)}
                />
            }
        </>
    );
}

export default Employee;