import PublicLayout from './PublicLayout';

interface IProps {
    children: React.ReactNode
}

const PublicRoute = ({ children }: IProps) => {
    return (
        <PublicLayout>
            {children}
        </PublicLayout>
    );
}

export default PublicRoute;