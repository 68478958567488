import React from 'react';
import { Page } from 'components';
import EmployeeDetailsProvider from './EmployeeDetailsContext';
import EmployeeDetails from './EmployeeDetails';


let Index = () => {
    return (
        <EmployeeDetailsProvider>
            <Page title="New employee" maxWidth='md'>
                <EmployeeDetails />
            </Page>
        </EmployeeDetailsProvider>
    );
}

export default Index;