import EmployeeProvider from './EmployeeContext';
import Employee from './Employee';
import { Page } from 'components';

let Index = () => {
    return (
        <EmployeeProvider>
            <Page title="Employee" noPaddingTop>
                <Employee />
            </Page>
        </EmployeeProvider>
    )
}

export default Index;