import React from 'react';
import { Form, Field, FieldArray, useFormikContext } from 'formik';
import { referenceDataSchema, referenceDataValueSchema } from '../ReferenceDataDetailContext';
import * as yup from 'yup';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Button, Divider, Grid, IconButton, Link, LinearProgress, Tooltip, Typography, Zoom } from '@mui/material';
import { TextField } from 'formik-mui';


interface IProps {
    isSorting: boolean
    sortBy: (sort: 'key' | 'description') => Promise<void>
}

let ReferenceDataForm = (props: IProps) => {
    let router = useLocation();

    let isNew = router.pathname.includes('/addnew');
    let title = isNew ? 'New reference data' : 'Edit reference data';

    type ReferenceData = yup.InferType<typeof referenceDataSchema>;

    let form = useFormikContext<ReferenceData>();

    return (
        <Form>
            <Grid container alignItems='center'>
                <Grid item xs>
                    <Typography gutterBottom variant="h3">{title}</Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        component={RouterLink}
                        to={'/admin/referencedata'}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
            {isNew === false && <Typography variant="h4">{form.values.key}</Typography>}
            {isNew === true &&
                <Field
                    component={TextField}
                    name="key"
                    fullWidth
                    size="small"
                    margin="normal"
                    label="Key"
                />
            }
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            <FieldArray name="values">
                {helpers =>
                    <>
                        <Grid container>
                            {form.values.values.length > 1 &&
                                <>
                                    <Grid item xs={3}>
                                        <Link
                                            component="button"
                                            disabled={props.isSorting}
                                            onMouseDown={() => props.sortBy('key')}
                                        >
                                            &nbsp;Sort by key
                                        </Link>
                                    </Grid>
                                    <Grid item xs>
                                        <Link
                                            component="button"
                                            disabled={props.isSorting}
                                            type="button"
                                            onMouseDown={() => props.sortBy('description')}
                                        >
                                            &nbsp;Sort by description
                                        </Link>
                                    </Grid>
                                </>
                            }
                            <Grid item xs>
                                <Link
                                    component="button"
                                    disabled={props.isSorting}
                                    style={{ float: 'right' }}
                                    type="button"
                                    onClick={() => {
                                        let maxSort = form.values.values.length === 0 ? 0 : Math.max(...form.values.values.map(x => x.sortOrder));

                                        helpers.insert(0, {
                                            ...referenceDataValueSchema.getDefault(),
                                            sortOrder: maxSort + 1
                                        })
                                    }}
                                >
                                    New value
                                </Link>
                            </Grid>
                        </Grid>
                        {props.isSorting && <LinearProgress style={{ marginTop: 8, marginBottom: 8 }} />}
                        {form.values.values
                            .map((value, index) =>
                                <Grid container key={index} alignItems='center'>
                                    <Grid item xs={3}>
                                        {value.isNew === true &&
                                            <Field
                                                component={TextField}
                                                name={`values[${index}].key`}
                                                label="Key"
                                                margin="dense"
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        }
                                        {!value.isNew && <Typography style={{ marginTop: 16 }} gutterBottom>{value.key}</Typography>}
                                    </Grid>
                                    <Grid item xs>
                                        <Field
                                            component={TextField}
                                            name={`values[${index}].description`}
                                            label="Description"
                                            margin="dense"
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Field
                                            component={TextField}
                                            name={`values[${index}].sortOrder`}
                                            label="Sort order"
                                            type="number"
                                            margin="dense"
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Remove" TransitionComponent={Zoom}>
                                            <IconButton
                                                color="secondary"
                                                size="small"
                                                onClick={() => helpers.remove(index)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            )}
                    </>
                }
            </FieldArray>
        </Form>
    )
}

export default ReferenceDataForm;