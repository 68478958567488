import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

interface IUserContext {
    isLoading: boolean
    user: User

    saveUser: (user: User) => void
}

export let userSchema = yup.object({
    id: yup.string().nullable().default(null),
    firstName: yup.string().required().default('').label('First name'),
    lastName: yup.string().required().default('').label('Last name'),
    email: yup.string().required().default('').label("Email"),
    canEditUsers: yup.boolean().default(false).label("can edit users"),
    canEditAddresses: yup.boolean().default(false).label("can edit addresses"),
    enabledCompanies: yup.array().of(yup.string().default('')).default([]).label("Enabled companies"),
});

type User = yup.InferType<typeof userSchema>;

export let userContext = React.createContext({} as IUserContext);

let { Provider } = userContext;

let UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { id } = useParams<{ id: string }>();
    let location = useLocation();
    let navigate = useNavigate();
    let { enqueueSnackbar } = useSnackbar();

    let isNew = location.pathname.includes("/addnew");

    let [isLoading, setLoading] = useState<boolean>(isNew === false);
    let [user, setUser] = useState<User>(userSchema.getDefault());
    let [isSaving, setSaving] = useState(false);


    useEffect(() => {
        if (isNew === false)
            getUser();

    }, [id, isNew])

    let getUser = async () => {
        try {
            let res = await axios.get('/api/UserVisibility/GetUserVisibility', { params: { id } });

            let { item } = res.data;
            setUser({
                ...item,
            });
            setLoading(false);

        } catch (err) { }
        finally {
        }
    }

    let saveUser = async (value: User) => {
        try {
            await axios.post('/api/UserVisibility/SaveUserVisibility', value);
            enqueueSnackbar("User saved", { variant: "success" });
            navigate(`/admin/users`);

        } catch (err) {
            setSaving(false)
        }
    }

    return (
        <Provider
            value={{
                isLoading,
                user,

                saveUser
            }}
        >
            {children}
        </Provider>
    )
}

export default UserProvider;