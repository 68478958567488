import { useMemo, useState } from 'react';
import { colors } from '@mui/material';
// import { makeStyles } from 'tss-react/mui';
import { Typography, Link } from '@mui/material';
import { Accept, useDropzone } from 'react-dropzone';
import { Alert, AlertTitle } from '@mui/material';
import theme from 'assets';


interface IProps {
    onDropCallback: (acceptedFiles: any) => any
    isMultiple: boolean,
    accept?: Accept
}

const baseStyle = {
    border: `2px dashed #560604`,
    padding: theme.spacing(3),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    cursor: 'pointer'
};

const dragActive = {
    backgroundColor: colors.grey[50],
    opacity: 0.5
};


let Dropzone = (props: IProps) => {
    let [errorMessage, setErrorMessage] = useState('');

    let { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: props.onDropCallback,
        multiple: props.isMultiple,
        accept: props.accept,
        onDropAccepted: () => setErrorMessage(''),
        onDropRejected: (files: any) => setErrorMessage(`These files could not be uploaded:${files.map((f: any) => ` ${f.file.name}`)}`)
    });

    const style: any = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? dragActive : {}),
    }), [isDragActive]);

    return (
        <>
            <div {...getRootProps({ style: style })}>
                <input {...getInputProps()} />
                <div>
                    <Typography gutterBottom variant="h3">Select files</Typography>
                    <Typography
                        color="textSecondary"
                        variant="body1"
                    >
                        Drop file here or click and <Link underline="always">browse</Link> thorough your machine
                    </Typography>
                </div>

            </div>
            {errorMessage !== '' &&
                <Alert style={{ marginTop: 16 }} severity="error">
                    <AlertTitle>
                        {props.accept &&
                            Array.isArray(props.accept)
                            ? `Only${props.accept.map(accept => ` ${accept}`)} are allowed.`
                            : `Only ${props.accept} files are allowed`
                        }
                    </AlertTitle>
                    {errorMessage}
                </Alert>
            }
        </>
    )
}

export default Dropzone;