import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';


interface IReferenceDataDetailContext {
    isLoading: boolean
    isSorting: boolean
    referenceData: ReferenceData

    saveReferenceData: (values: ReferenceData) => Promise<void>
    sortBy: (sort: 'key' | 'description') => Promise<void>
}

export let referenceDataDetailContext = React.createContext({} as IReferenceDataDetailContext);

export let referenceDataValueSchema = yup.object({
    key: yup.string().default('').required("Key is required field"),
    description: yup.string().default('').required("Description is required field"),
    sortOrder: yup.number().default(1).required("SortOrder is required field"),
    isNew: yup.boolean().default(true).nullable()
})

export let referenceDataSchema = yup.object({
    id: yup.string().default(''),
    key: yup.string().default('').required("Key is required field"),
    values: yup.array(referenceDataValueSchema).default([])
});

type ReferenceData = yup.InferType<typeof referenceDataSchema>;

let { Provider } = referenceDataDetailContext;

let ReferenceDataDetailProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let location = useLocation();
    let navigate = useNavigate();

    let { id } = useParams<{ id: string }>();
    let isNew = location.pathname.includes("/addnew");

    let [isLoading, setLoading] = useState<boolean>(true);
    let [isSorting, setSorting] = useState<boolean>(false);
    let [referenceData, setReferenceData] = useState<ReferenceData>(referenceDataSchema.getDefault());


    useEffect(() => {
        if (isNew) {
            setLoading(false);
        } else {
            getReferenceData();
        }
    }, [])


    let getReferenceData = async () => {
        try {
            let res = await axios.get('/api/ReferenceData/GetReferenceData', { params: { id } });
            setReferenceData(res.data.item);
        } catch (err: any) { }
        finally {
            setLoading(false);
        }
    }

    let saveReferenceData = async (values: ReferenceData) => {
        try {
            await axios.post('/api/ReferenceData/SaveReferenceData', values);
            navigate(`/admin/referencedata`);

        } catch (err: any) { }
    }

    let sortBy = async (sort: 'key' | 'description') => {
        if (window.confirm(`Are you sure that you want to sort reference data by ${sort}?`)) {
            try {
                setSorting(true);

                let request = {
                    referenceDataId: referenceData.id,
                    sortBy: sort
                }
                await axios.put('/api/ReferenceData/SortReferenceDataBy', request);
                await getReferenceData();
            }
            catch (err: any) { }
            finally {
                setSorting(false);
            }
        }
    }


    return (
        <Provider
            value={{
                isLoading,
                isSorting, 
                referenceData,

                saveReferenceData,
                sortBy
            }}
        >
            {children}
        </Provider>
    )
}

export default ReferenceDataDetailProvider;