import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ICurrentUser } from 'helpers/interfaces';
import { ReferenceDataKey } from 'helpers/enums';
import KeyValuePair from 'helpers/KeyValuePair';
import { Dictionary } from 'lodash';
import { useMediaQuery } from '@mui/material';
import theme from 'assets';


interface IApplicationContext {
    isLoading: boolean
    referenceData: IReferenceData
    currentUser: ICurrentUser
    isDesktop: boolean
}

interface IReferenceData {
    [ReferenceDataKey.Country]: ICountry[]
    values: Dictionary<KeyValuePair<string, string>[]>
}

interface ICountry {
    region: string
    countryName: string
    isoCode: string
}

export let applicationContext = React.createContext({} as IApplicationContext);

let { Provider } = applicationContext;

let ApplicationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    let [isLoading, setLoading] = useState(true);
    let [currentUser, setCurrentUser] = useState({} as ICurrentUser);
    let [referenceData, setReferenceData] = useState({} as IReferenceData);

    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        getReferenceData();
    }, []);

    let getReferenceData = async () => {
        try {
            setLoading(true);

            let res = await axios.get('/api/ReferenceData/GetReferenceDataValues');

            let { item } = res.data;
            setCurrentUser(item.currentUser);
            setReferenceData({ Country: item.countries, values: item.values });

            setLoading(false);
        } catch (err) { }
    }

    return (
        <Provider value={{
            isLoading,
            referenceData,
            currentUser,
            isDesktop: isDesktop
        }}>
            {children}
        </Provider>
    )
}

export default ApplicationProvider;