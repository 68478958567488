import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, Alert } from '@mui/material';
import Moment from 'react-moment';


interface ICannotEditEmployee {
    open: boolean
    canEditFrom?: moment.Moment

    onClose: () => void
}


let CannotEditEmployee = (props: ICannotEditEmployee) => {

    return (
        <Dialog
            open={props.open}
            maxWidth="md"
            fullWidth={true}
            scroll="paper"
        >
            <DialogTitle>
                <Typography variant="h3">
                    Employee cannot be edited
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity='warning'>
                            This users has been edited recently and cannot be updated before <Moment date={props.canEditFrom} format='LL LT' />
                        </Alert>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onClose}
                    variant="outlined"
                    color="primary"
                    size="medium"
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default CannotEditEmployee;