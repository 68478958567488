import { useState, useContext, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Grid, Typography, TextField, TablePagination, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, MenuItem } from '@mui/material';
import { applicationContext } from 'helpers/services';
import { SearchOutlined as SearchIcon } from '@mui/icons-material';
import axios from 'axios';
import { CircleLoader, FilterContainer, SelectIconButton } from 'components';
import { FormikProps } from 'formik';


interface IAddCategories {
    formProps: FormikProps<any>

    onClose: () => void
}

interface ISearch {
    street: string
    postalCode: string
    city: string
    province: string
    country: string

    pageSize: number
    pageNr: number
}

interface IAddress {
    addressId: string

    street: string
    postalCode: string
    city: string
    province: string
    country: string
}

let SelectAddress = (props: IAddCategories) => {

    let { referenceData } = useContext(applicationContext);

    let [isSaving, setSaving] = useState<boolean>(false);

    let [isSearching, setSearching] = useState<boolean>(false);
    let [search, setSearch] = useState<ISearch>({
        street: '',
        postalCode: '',
        city: '',
        province: '',
        country: '',

        pageNr: 0,
        pageSize: 15
    });


    let [addresses, setAddresses] = useState<IAddress[]>([]);
    let [recordCount, setTotalRecords] = useState<number>(0);

    useEffect(() => {
        searchAddresses({ ...search });
    }, []);


    let searchAddresses = async (searchRequest: ISearch) => {
        try {
            setSearching(true);
            let response = await axios.post('/api/Address/SearchAddressesFromDialog', searchRequest);

            setAddresses(response.data.items);
            setTotalRecords(response.data.recordCount);
            setSearch({ ...searchRequest });

        } catch (error) { }
        finally {
            setSearching(false);
        }
    }

    let handleChangePage = (event: any, page: number) => {
        let request: ISearch = {
            ...search,
            pageNr: page
        };

        setSearch({ ...request });
        searchAddresses({ ...request });
    }

    let selectAddress = (address: IAddress) => {
        if (window.confirm("Are you sure that you want to select this address?")) {
            setSaving(true);

            // retrieve country description at first
            let addressCountry: string = referenceData.Country.getValueByKey(address.country, 'isoCode', 'countryName');

            // set the following fields:
            props.formProps.setFieldValue('street', address.street);
            props.formProps.setFieldValue('postalCode', address.postalCode);
            props.formProps.setFieldValue('city', address.city);
            props.formProps.setFieldValue('country', addressCountry);
            props.formProps.setFieldValue('province', address.province);


            props.onClose();
        }
    }

    return (
        <Dialog
            open={true}
            maxWidth="xl"
            fullWidth={true}
            scroll="paper"
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>
                <Typography variant="h3">
                    Select Address
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FilterContainer stopPropagation>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Street"
                                    value={search.street}
                                    onChange={(e: any) => setSearch({
                                        ...search,
                                        street: e.target.value
                                    })}
                                />
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Postal Code"
                                    value={search.postalCode}
                                    onChange={(e: any) => setSearch({
                                        ...search,
                                        postalCode: e.target.value
                                    })}
                                />
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="City"
                                    value={search.city}
                                    onChange={(e: any) => setSearch({
                                        ...search,
                                        city: e.target.value
                                    })}
                                />
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Province"
                                    value={search.province}
                                    onChange={(e: any) => setSearch({
                                        ...search,
                                        province: e.target.value
                                    })}
                                />
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Country"
                                    value={search.country}
                                    select
                                    onChange={(e: any) => setSearch({
                                        ...search,
                                        country: e.target.value
                                    })}
                                >
                                    {referenceData.Country
                                        .map((locale, index) =>
                                            <MenuItem key={index} value={locale.isoCode}>
                                                {locale.countryName}
                                            </MenuItem>
                                        )}
                                </TextField>
                            </Grid>
                            <Grid item xs>
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    aria-label="search"
                                    onClick={() => searchAddresses({ ...search })}
                                >
                                    <SearchIcon />
                                </Button>
                            </Grid>
                        </FilterContainer>
                    </Grid>
                    <Grid item xs={12}>
                        {isSearching === true && <CircleLoader />}
                        {isSearching !== true &&
                            <>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Street</TableCell>
                                            <TableCell>Postal Code</TableCell>
                                            <TableCell>City</TableCell>
                                            <TableCell>Province</TableCell>
                                            <TableCell>Country</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {addresses.length > 0 &&
                                            addresses.map((address, index) =>
                                                <TableRow hover key={index}>
                                                    <TableCell>{address.street}</TableCell>
                                                    <TableCell>{address.postalCode}</TableCell>
                                                    <TableCell>{address.city}</TableCell>
                                                    <TableCell>{address.province}</TableCell>
                                                    <TableCell>
                                                        {address.country && referenceData.Country.getValueByKey(address.country, 'isoCode', 'countryName')}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <SelectIconButton onClick={() => selectAddress(address)} />
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                    </TableBody>
                                    {addresses.length === 0 &&
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan={6}>
                                                    <Typography variant="subtitle2">No addresses found</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    }
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={recordCount}
                                    onPageChange={handleChangePage}
                                    page={search.pageNr}
                                    rowsPerPage={search.pageSize}
                                    rowsPerPageOptions={[search.pageSize]}
                                />
                            </>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {isSaving && <CircularProgress size={24} />}
                <Button
                    disabled={isSaving}
                    onClick={props.onClose}
                    variant="outlined"
                    color="primary"
                    size="medium"
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default SelectAddress;