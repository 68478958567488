import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { useSnackbar } from "notistack";
import moment from "moment";

interface IEmployeeContext {
    employee: IEmployee
    isLoading: boolean

    getEmployee: () => void
}


interface IEmployee {
    id: string
    firstName: string
    lastName: string
    jobTitle: string
    companyName: string
    department: string
    street: string
    postalCode: string
    city: string
    province: string
    country: string
    telephoneNumber: string
    mobile: string
    internalNumber: string
    email: string
    previewPage: string
    enableQRCode: boolean
    canBeEdited: boolean
    canBeEditedFrom?: moment.Moment
}

export let employeeContext = React.createContext<IEmployeeContext>({} as IEmployeeContext);

let { Provider } = employeeContext;

let EmployeeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { id } = useParams<{ id: string }>();
    let { enqueueSnackbar } = useSnackbar();

    let [isLoading, setLoading] = useState<boolean>(true);
    let [employee, setEmployee] = useState<IEmployee>({} as IEmployee);

    useEffect(() => {
        getEmployee();
    }, []);

    let getEmployee = async () => {
        try {
            let res = await axios.get('/api/Employee/GetEmployee', { params: { id: id } });

            let { item } = res.data;
            setEmployee({
                ...item,
            });

        } catch (err) { }
        finally {
            setLoading(false);
        }
    }

    return (
        <Provider value={{
            employee,
            isLoading,

            getEmployee
        }}>
            {children}
        </Provider>
    );
};

export default EmployeeProvider;