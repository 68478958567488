import { Grid, Typography } from "@mui/material";
import { FilterContainer } from "components";
import { Filters, Results } from "./components";

let Addresses = () => {

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant='h3'>Addresses</Typography>
            </Grid>
            <Grid item xs={12}>
                <FilterContainer>
                    <Filters />
                </FilterContainer>
            </Grid>
            <Grid item xs={12}>
                <Results />
            </Grid>
        </Grid>
    );
}

export default Addresses;