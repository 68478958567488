import { Navigate, Route, Routes as Router } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from 'components';

import {
    Error403,
    Error404,
    Homepage,
    Employees,
    Employee,
    EmployeeDetails,
} from 'views';

import { Login } from 'views/Account';

import {
    Users,
    UserDetails,
    Addresses,
    AddressDetails,
    ReferenceData,
    ReferenceDataDetail,
    Companies,
    CompanyDetails
} from 'views/Admin';

let Routes = () => {
    return (
        <Router>
            <Route path="/" element={<PrivateRoute children={<Homepage />} />} />

            <Route path="/employees" element={<PrivateRoute children={<Employees />} />} />
            <Route path="/employee/:id" element={<PrivateRoute children={<Employee />} />} />
            <Route path="/employee/addnew" element={<PrivateRoute children={<EmployeeDetails />} />} />
            <Route path="/employee/edit/:id" element={<PrivateRoute children={<EmployeeDetails />} />} />

            <Route path="/admin/users" element={<PrivateRoute children={<Users />} />} />
            <Route path="/admin/user/addnew" element={<PrivateRoute children={<UserDetails />} />} />
            <Route path="/admin/user/edit/:id" element={<PrivateRoute children={<UserDetails />} />} />

            <Route path="/admin/addresses" element={<PrivateRoute children={<Addresses />} />} />
            <Route path="/admin/address/addnew" element={<PrivateRoute children={<AddressDetails />} />} />
            <Route path="/admin/address/edit/:id" element={<PrivateRoute children={<AddressDetails />} />} />

            <Route path="/admin/referencedata" element={<PrivateRoute children={<ReferenceData />} />} />
            <Route path="/admin/referencedata/addnew" element={<PrivateRoute children={<ReferenceDataDetail />} />} />
            <Route path="/admin/referencedata/edit/:id" element={<PrivateRoute children={<ReferenceDataDetail />} />} />

            <Route path="/admin/companies" element={<PrivateRoute children={<Companies />} />} />
            <Route path="/admin/company/addnew" element={<PrivateRoute children={<CompanyDetails />} />} />
            <Route path="/admin/company/edit/:id" element={<PrivateRoute children={<CompanyDetails />} />} />

            <Route path="/account/login" element={<PublicRoute children={<Login />} />} />

            <Route path="/error403" element={<PublicRoute children={<Error403 />} />} />
            <Route path="/error404" element={<PublicRoute children={<Error404 />} />} />

            <Route path="*" element={<Navigate to={"/error404"} />} />
        </Router>
    )
}

export default Routes;