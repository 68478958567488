import { Button, Grid, Typography, FormControlLabel, Checkbox, MenuItem } from '@mui/material';
import { Form, Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import * as yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import { userSchema } from '../UserDetailsContext';
import theme from 'assets';
import { useContext } from 'react';
import { applicationContext } from 'helpers/services';
import { ReferenceDataKey } from 'helpers/enums';

let UserForm = () => {
    let router = useLocation();
    let appService = useContext(applicationContext);

    let isNew = router.pathname.includes('/addnew');
    let title = isNew ? 'New user' : `Edit user`;

    type User = yup.InferType<typeof userSchema>;
    let form = useFormikContext<User>();


    return (
        <Form>
            <Grid container alignItems='center'>
                <Grid item xs>
                    <Typography gutterBottom variant="h3">{title}</Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        component={Link}
                        to={'/admin/users'}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
            <Grid container alignItems='center' style={{ marginTop: theme.spacing(1) }}>
                <Grid item xs={12}>
                    <Field
                        component={TextField}
                        name="email"
                        label="Email"
                        disabled={isNew === false}
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="firstName"
                        label="First name"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        name="lastName"
                        label="Last name"
                        fullWidth
                        margin="dense"
                        size='small'
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography><b>Permissions</b></Typography>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        label="Can edit users"
                        name="canEditUsers"
                        value={form.values.canEditUsers}
                        checked={form.values.canEditUsers}
                        onChange={(e: any) => form.setFieldValue('canEditUsers', e.target.checked)}
                        control={<Field type="checkbox" name="canEditUsers" component={Checkbox} />}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        label="Can edit addresses"
                        name="canEditAddresses"
                        value={form.values.canEditAddresses}
                        checked={form.values.canEditAddresses}
                        onChange={(e: any) => form.setFieldValue('canEditAddresses', e.target.checked)}
                        control={<Field type="checkbox" name="canEditAddresses" component={Checkbox} />}
                    />
                </Grid>
                <Grid item xs>
                    <Field
                        component={TextField}
                        select
                        name="enabledCompanies"
                        label="Enabled companies"
                        fullWidth
                        margin="dense"
                        size='small'
                        SelectProps={{ multiple: true }}
                    >
                        {appService.referenceData.values[ReferenceDataKey.Company].map(company =>
                            <MenuItem key={company.key} value={company.key}>{company.value}</MenuItem>
                        )}
                    </Field>
                </Grid>
            </Grid>
        </Form>
    );
}

export default UserForm;