import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from "moment";


interface IUsersContext {
    recordCount: number
    search: ISearch
    users: IUser[]
    isLoading: boolean

    doSearch: (search: ISearch) => void
    handleChangePage: (event: any, page: number) => void
    updateSearch: (newSearch: ISearch) => void
    searchUsers: (searchRequest: ISearch) => Promise<void>
    deleteUser: (id: string) => Promise<void>
}

export let usersContext = React.createContext<IUsersContext>({} as IUsersContext);

let { Provider } = usersContext;

interface IUser {
    id: string
    email: string
    name: string
    createdOn: moment.Moment
    lastUpdatedOn: moment.Moment
}

export interface ISearch {
    fullText: string
    pageNr: number
    pageSize: number
}

export let UsersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { enqueueSnackbar } = useSnackbar();
    let location = useLocation();
    let navigate = useNavigate();

    let [isLoading, setLoading] = useState(true);


    let [users, setUsers] = useState<IUser[]>([]);
    let [recordCount, setTotalRecords] = useState(0);

    let [search, setSearch] = useState<ISearch>({
        fullText: '',
        pageNr: 0,
        pageSize: 20
    });

    useEffect(() => {
        if (location.state)
            processState(location.state);

    }, [location.state])


    if (!location.state)
        return <Navigate to={location.pathname} state={{ search: search }} replace={true} />


    let processState = (state: any) => {
        if (state) {

            setSearch({
                ...search,
                ...state
            });

            searchUsers({
                ...search,
                ...state
            });
        }
    }

    let doSearch = (values: ISearch) => {
        let request = {
            ...values,
            pageNr: values.pageNr > 0 ? 0 : values.pageNr
        }

        navigate(location.pathname, { state: request });
    }

    let updateSearch = (newSearch: ISearch) => {
        setSearch(newSearch);
    }

    let searchUsers = async (searchRequest: ISearch) => {
        try {
            setLoading(true);
            let response = await axios.post('/api/UserVisibility/SearchUserVisibilities', searchRequest);

            setUsers(response.data.items);
            setTotalRecords(response.data.recordCount);
            setSearch({ ...searchRequest });

        } catch (error) { }
        finally {
            setLoading(false);
        }
    }

    let handleChangePage = (event: any, page: number) => {
        let request = {
            ...search,
            pageNr: page
        }

        window.scrollTo(0, 0);
        navigate(location.pathname, { state: request });
    }

    let deleteUser = async (id: string) => {
        if (window.confirm("Are you sure you want to delete the user?")) {
            try {
                await axios.delete(`/api/UserVisibility/DeleteUserVisibility/${id}`)
                setUsers(users.filter(user => user.id !== id));
                setTotalRecords(recordCount - 1);
                enqueueSnackbar("User deleted", { variant: 'success' });
            } catch (error) { }
        }
    }


    return (
        <Provider value={{
            recordCount,
            search,
            users,
            isLoading,

            doSearch,
            handleChangePage,
            updateSearch,
            searchUsers,
            deleteUser
        }}>
            {children}
        </Provider>
    );
};