import React from 'react';
import { Page } from 'components';
import { AddressesProvider } from './AddressesContext';
import Addresses from './Addresses';


let Index = () => {
    return (
        <AddressesProvider>
            <Page title="Addresses">
                <Addresses />
            </Page>
        </AddressesProvider>
    );
}

export default Index;