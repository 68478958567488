import { useContext, useState } from 'react';
import { List, ListItemButton, ListItemText, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { Settings } from '@mui/icons-material';
import { applicationContext } from 'helpers/services';


let AdminMenu = () => {
    let { currentUser } = useContext(applicationContext);

    let [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    let handleOpen = (e: any) => setAnchorEl(e.currentTarget);

    let onClose = () => setAnchorEl(null);


    return (
        <>
            <List component="nav" disablePadding>
                <ListItemButton onClick={(e) => handleOpen(e)}>
                    <ListItemText primary={<Settings />} />
                </ListItemButton>
            </List>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={anchorEl !== null}
                onClose={onClose}
            >
                {currentUser.canEditUsers === true &&
                    <MenuItem component={Link} to={'/admin/users'} onClick={onClose}>Users</MenuItem>
                }
                {currentUser.canEditAddresses === true &&
                    <MenuItem component={Link} to={'/admin/addresses'} onClick={onClose}>Addresses</MenuItem>
                }
                <MenuItem component={Link} to={'/admin/companies'} onClick={onClose}>Companies</MenuItem>
                <MenuItem component={Link} to={'/admin/referencedata'} onClick={onClose}>Reference data</MenuItem>
            </Menu>
        </>
    )
}

export default AdminMenu;