import { useContext } from 'react';
import { Formik } from 'formik';
import { CircleLoader } from 'components';
import AddressForm from './components';
import { addressContext, addressSchema } from './AddressDetailsContext';

let AddressDetails = () => {

    let service = useContext(addressContext);

    return (
            <Formik
                enableReinitialize={true}
                initialValues={service.address}
                validationSchema={addressSchema}
                onSubmit={(values, action) => {
                    service.saveAddress(values);
                    action.setSubmitting(false);;
                }}
            >
                <>
                    {service.isLoading && <CircleLoader />}
                    {service.isLoading === false && <AddressForm />}
                </>
            </Formik>
    )
}

export default AddressDetails;